import {BLEND_MODES, Container, Graphics, Matrix, Point, Polygon, Sprite, Texture, utils} from "pixi.js";
import {drawSpline, drawSplineClosed, formatPoints} from "../utils/Helpers";
import {Globals} from "../utils/Globals";
import {pointsInPath} from '@georgedoescode/generative-utils'
import {gsap} from 'gsap';
import {RecordingAPI} from "./RecordingAPI";
import {AudioAnalyser} from "./AudioAnalyser";

const {SimplexNoise} = require('simplex-noise');
const simplex = new SimplexNoise();

function noise(x, y) {
    return simplex.noise2D(x, y);
}

function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

const micBGSvg = `<svg width="242" height="246" viewBox="0 0 242 246" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M221.777 198.576c21.931-33.395 24.065-77.309 11.622-115.24-8.142-24.883-22.62-48.286-43.961-63.34a111.526 111.526 0 0 0-71.966-18.572 128.563 128.563 0 0 0-58.95 19.743 128.752 128.752 0 0 0-42.826 45.109 122.819 122.819 0 0 0-14.678 60.373 122.81 122.81 0 0 0 16.746 59.831c30.927 51.146 109.098 73.694 163.138 47.596a102.245 102.245 0 0 0 40.875-35.5Z" fill="#FBDFBE"/></svg>`;

export class RecordingUI {
    private fill = 0xFBDFBE;
    private fillColorSplit = gsap.utils.splitColor("#FBDFBE", false).map((value) => value / 255);
    private bgColorSplit = gsap.utils.splitColor("#FEF7EF", false).map((value) => value / 255);
    public container: Container = new Container();
    public innerContainer: Container = new Container();
    private points: any;
    private flatPoints: number[];
    private pathPointCount = 6; //Works best with power of two's, 4, 16, 32, 64...
    private hitAreaPointCount = 16; //Works best with power of two's, 4, 16, 32, 64...
    private path: SVGPathElement;
    private totalPoints = 0;
    private totalPointsFlat = 0;
    private texture: Texture;
    public el: Graphics;
    public elOutline: Graphics;
    public tension = 1;
    public noiseStep = {
        value: 0.002,
        speed: 0.8,
        currValue: 0.002,
        noiseStepMin: 0.002,
        noiseStepMax: 0.1,
        maxOffset: 10
    };
    private textureMatrix = new Matrix();
    private textureScaleFactor = 0.5;

    private textureFill: { color: number; texture: Texture; alpha: number, matrix: Matrix };
    private recordingAPI: RecordingAPI;
    private audioAnalyser: AudioAnalyser;

    private recordingLabel: HTMLElement = document.querySelector('.RecordingUI-label');
    private domContainer: HTMLElement = document.querySelector('.RecordingUI');
    private recording = false;
    private recordingTimeoutBlocking = false;

    private dummyColorProxy = {value: 0};

    private scaleFactor = 0.75;

    private noiseScale = {
        currScale: 1,
        toScale: 1,
        speed: 0.2
    }

    private experienceBlocked = false;

    constructor(texture, recordingAPI: RecordingAPI) {
        this.audioAnalyser = new AudioAnalyser();
        this.recordingAPI = recordingAPI;
        this.recordingAPI.setAudioAnalyserStream(this.audioAnalyser.setMediaStream);
        this.texture = texture;
        this.textureFill = {texture: this.texture, color: this.fill, alpha: 1.0, matrix: this.textureMatrix};

        const svgContainer = document.createElement('div');
        svgContainer.innerHTML = micBGSvg;
        this.path = svgContainer.querySelector('path');

        this.el = new Graphics();
        this.elOutline = new Graphics();
        this.innerContainer.addChild(this.el);
        this.innerContainer.addChild(this.elOutline);
        this.container.addChild(this.innerContainer);
        this.el.pluginName = 'blob';
        this.elOutline.pluginName = 'batch';

        this.setupPoints();

        this.colorTweenBG();
        this.rafSpline();
        this.container.visible = false;
        this.container.alpha = 0;
        this.resize();

        // @ts-ignore
        this.el.interactive = false;
        // @ts-ignore
        this.el.buttonMode = true;
        // @ts-ignore
        this.el.on('pointerdown', () =>  !this.experienceBlocked && this.recordingActive(true)).on('pointerup', () => !this.experienceBlocked && this.stopRecording());

        this.recordingAPI.mediaRecorderReadyCallback = this.mediaRecorderReady;
        Globals.STATE_MANAGER.router.signalRouteChange.add((activeID:string) => {
            if (activeID !== '/') {
                this.experienceBlocked = true;
                if (this.recording) {
                    this.stopRecording(true);
                }
            } else {
                this.experienceBlocked = false;
            }
        });
    }

    keyUpHandler = (event: KeyboardEvent) => {
        if (event.code === 'Space' && !this.experienceBlocked) {
            this.stopRecording();
        }
    }

    private stopRecording = (skipResults = false) => {
        if (this.recordingTimeoutBlocking) return false;
        this.recordingTimeoutBlocking = true;
        setTimeout(() => {
            this.recordingAPI.stopRecording(skipResults);
            this.recordingTimeoutBlocking = false;
        }, 1000)
        this.recording = false;
        gsap.killTweensOf(this.elOutline);
        gsap.killTweensOf(this.dummyColorProxy);
        gsap.killTweensOf(this.noiseScale);
        gsap.to(this.noiseScale, {speed: 0.02, ease: 'Sine.inOut', duration: 0.5});
        gsap.to(this.dummyColorProxy, {value: 0, ease: 'Sine.inOut', duration: 0.5, onUpdate: this.colorTweenBG});
        gsap.to(this.elOutline, {alpha: 1, ease: 'Sine.inOut', duration: 0.5});
        this.domContainer.classList.remove('recording');
    }

    onWindowBlur = (event: KeyboardEvent) => {
        if (!this.experienceBlocked) {
            this.stopRecording();
        }
    }

    keyDownHandler = (event: KeyboardEvent) => {
        if (event.code === 'Space' && !this.experienceBlocked) {
            this.recordingActive(true);
        }
    }

    private recordingActive = (value:boolean) => {
        if (this.recordingTimeoutBlocking) return false;
        if (value) {
            if (this.experienceBlocked) {
                return;
            }
            this.recordingAPI.startRecording();
            this.recording = true;
            gsap.killTweensOf(this.elOutline);
            gsap.killTweensOf(this.dummyColorProxy);
            gsap.killTweensOf(this.noiseScale);
            gsap.to(this.noiseScale, {speed: 0.2, ease: 'Sine.inOut', duration: 0.5});
            gsap.to(this.dummyColorProxy, {value: 1, ease: 'Sine.inOut', duration: 0.5, onUpdate: this.colorTweenBG});
            gsap.to(this.elOutline, {alpha: 0, ease: 'Sine.inOut', duration: 0.5});
            this.domContainer.classList.add('recording');
        } else {
            this.stopRecording();
        }
    }

    private colorTweenBG = () => {
        this.textureFill.color = utils.rgb2hex(gsap.utils.interpolate(this.bgColorSplit, this.fillColorSplit, this.dummyColorProxy.value));
    }

    private setupPoints = () => {
        this.points = pointsInPath(this.path, this.pathPointCount);
        this.points = this.points.map(point => {
            return {
                x: point.x,
                y: point.y,
                // we need to keep a reference to the point's original point for when we modulate the values later
                originX: point.x,
                originY: point.y,
                // more on this in a moment!
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000
            }
        });
        // @ts-ignore
        this.el.hitArea = new Polygon([...pointsInPath(this.path, this.hitAreaPointCount)].map(({x, y}) => [x, y]).flat());
        this.flatPoints = formatPoints(this.points, true, true);
        this.totalPoints = this.points.length;
        this.totalPointsFlat = this.flatPoints.length;
        this.points = formatPoints(this.points, false, false);
    }

    public rafSpline = () => {
        this.el.clear();
        this.elOutline.clear();
        if (this.recording) {
            this.noiseStep.value = gsap.utils.clamp(this.noiseStep.noiseStepMin, this.noiseStep.noiseStepMax, this.audioAnalyser.getAverageFrequency() / 500);
            this.noiseScale.toScale = 1 + this.audioAnalyser.getAverageFrequency() / 80;
            // console.log(this.audioAnalyser.getAverageFrequency());
        } else {
            this.noiseScale.toScale = 1;
            this.noiseStep.value = this.noiseStep.noiseStepMin;
        }
        const deltaRatio = isNaN(gsap.ticker.deltaRatio()) ? 1 : gsap.ticker.deltaRatio();
        const dtNoise = 1.0 - Math.pow(1.0 - this.noiseStep.speed, deltaRatio);
        this.noiseStep.currValue += (this.noiseStep.value - this.noiseStep.currValue) * dtNoise;
        const dtScale = 1.0 - Math.pow(1.0 - this.noiseScale.speed, deltaRatio);
        this.noiseScale.currScale += (this.noiseScale.toScale - this.noiseScale.currScale) * dtScale;
        this.container.scale.x = this.container.scale.y = this.noiseScale.currScale * this.scaleFactor;


        this.elOutline.lineStyle(2 / this.container.scale.x, this.fill, 1, 1);
        this.elOutline.pluginName = 'batch'
        // this.el.lineStyle(2 / this.el.scale.x, this.fill, 1);
        // this.textureMatrix.a = 1 / this.container.scale.x * this.textureScaleFactor;
        // this.textureMatrix.d = 1 / this.container.scale.y * this.textureScaleFactor;
        // console.log(this.textureMatrix);
        // this.el.pluginName = 'blob'
        this.el.beginTextureFill(this.textureFill);


        // let moveSin = Math.sin(this.ticker) * this.parkinson;
        // let moveCos = Math.cos(this.ticker) * this.parkinson;
        // this.ticker += 0.01;
        // this.el.position.x = moveSin * 2 + this.origin.x;
        // this.el.position.y = moveCos * 2 + this.origin.y;
        for (let i = 0; i < this.totalPoints; i++) {
            // if (i % this.pointMorphSkips === 0) {
            const point = this.points[i];

            // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
            const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
            const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
            // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
            const x = map(nX, -1, 1, point.originX - this.noiseStep.maxOffset, point.originX + this.noiseStep.maxOffset);
            const y = map(nY, -1, 1, point.originY - this.noiseStep.maxOffset, point.originY + this.noiseStep.maxOffset);

            // update the point's current coordinates
            point.x = x;
            point.y = y;
            this.flatPoints[i * 2 + 4] = x;
            this.flatPoints[i * 2 + 1 + 4] = y;
            // progress the point's x, y values through "time"
            point.noiseOffsetX += this.noiseStep.currValue;
            point.noiseOffsetY += this.noiseStep.currValue;
        }
        //Sync the non-closed form this.points array to the closed form this.flatPoints array. See the formatPoints() method for close logic points shifting.
        let point = this.points[this.totalPoints - 1]; // last point
        this.flatPoints[2] = point.x;
        this.flatPoints[3] = point.y;
        point = this.points[this.totalPoints - 2]; // second to last point
        this.flatPoints[0] = point.x;
        this.flatPoints[1] = point.y;
        point = this.points[0]; // first point:
        this.flatPoints[this.totalPointsFlat - 4] = point.x;
        this.flatPoints[this.totalPointsFlat - 3] = point.y;
        point = this.points[1]; // second point:
        this.flatPoints[this.totalPointsFlat - 2] = point.x;
        this.flatPoints[this.totalPointsFlat - 1] = point.y;

        drawSplineClosed(this.flatPoints, this.tension, this.el, this.elOutline);
        // this.plotPathPoints();

        this.elOutline.endFill();
        this.el.endFill();


    }

    tempPoints: Graphics[] = [];
    plotPathPoints = () => {
        this.tempPoints.forEach((el) => el.destroy());
        this.tempPoints = [];
        for (let i = 0; i < this.flatPoints.length; i += 2) {
            var el = new Graphics();
            el.beginFill(i === 0 || i == this.flatPoints.length - 2 ? 0x000000 : 0xff2080, 0.5);
            el.drawCircle(this.flatPoints[i], this.flatPoints[i + 1], 3 + i / 2);
            el.endFill();
            this.tempPoints.push(el);
            this.container.addChild(el);
        }
        var el = new Graphics();
        el.beginFill(0x549560, 0.5);
        el.drawCircle(0, 0, 4);
        el.endFill();
        this.tempPoints.push(el);
        this.container.addChild(el);
    }

    public resize = () => {
        const scaleFactor = Globals.winW < 768 || Globals.winH < Globals.BP_HEIGHT ? 0.6 : 1;
        this.container.pivot.x = this.el.width * scaleFactor / 2;
        this.container.pivot.y = this.el.height * scaleFactor / 2;

        this.container.position.x = (Globals.winW - Globals.menuWidth) / 2 + Globals.menuWidth;
        this.container.position.y = Globals.winH / 2
        this.textureMatrix.a = 1 /*/ this.container.scale.x*/ * this.textureScaleFactor;
        this.textureMatrix.d = 1 /* / this.container.scale.y*/ * this.textureScaleFactor;

        this.innerContainer.scale.x = this.innerContainer.scale.y = scaleFactor;
        this.rafSpline();
    }

    mediaRecorderReady = () => {
       this.recordingInputEnabled(true);
    }

    public recordingInputEnabled = (value: boolean) => {
        if (value === true) {
            // @ts-ignore
            this.el.interactive = true;
            window.addEventListener('keydown', this.keyDownHandler);
            window.addEventListener('keyup', this.keyUpHandler);
            window.addEventListener('blur', this.onWindowBlur);
        } else {
            // @ts-ignore
            this.el.interactive = false;
            window.removeEventListener('keydown', this.keyDownHandler);
            window.removeEventListener('keyup', this.keyUpHandler);
            window.removeEventListener('blur', this.onWindowBlur);
        }
    }

    initMediaRecorder = () => {
        this.recordingAPI.initMediaRecorder();
    }

    show = () => {
        this.container.visible = true;
        this.domContainer.style.visibility = 'visible';
        gsap.to(this, {scaleFactor: 1, duration: 1.4, ease: 'power3.inOut'});
        gsap.to(this.container, {alpha: 1, duration: 1.2, ease: 'sine.inOut'});
        this.domContainer.classList.add('visible');
        this.domContainer.classList.remove('hidden', 'reset');
        gsap.delayedCall(1.5, () => {
            this.domContainer.classList.add('ready');
        })
        if (!Globals.DEBUG_DISABLE_MIC) {
            gsap.delayedCall(2, () => {
                this.initMediaRecorder();
            })
        }
    }

    hide = (hardReset: boolean = false) => {
        const scaleTime = hardReset ? 0 : 1.4;
        const alphaTime = hardReset ? 0 : 1.2;
        this.recordingInputEnabled(false);
        this.recordingAPI.closeConnection();
        if (hardReset) {
            this.domContainer.classList.add('reset');
        }
        gsap.to(this, {scaleFactor: 0.75, duration: scaleTime, ease: 'power3.inOut'});
        gsap.to(this.container, {alpha: 0, duration: alphaTime, ease: 'sine.inOut'});
        this.domContainer.classList.add('hidden');
        this.domContainer.classList.remove('ready');
    }
}