// import {Pane} from "tweakpane";
import {NotificationsUI} from "../components/NotificationsUI";
import * as Bowser from "bowser";
import {StateManager} from "../components/StateManager";
import {BlobAudioPlayer} from "../components/BlobAudioPlayer";
import {IAPIResponse} from "../components/RecordingAPI";
import {Signal} from "signal-ts";
import { MouseMoveTracker } from "./MouseMoveTracker";
declare class DocumentTouch {}

export class Globals {
	public static DEBUG: boolean = false;
	public static DEBUG_DISABLE_MIC: boolean = false;
    public static browser = Bowser.getParser(window.navigator.userAgent);
	public static IS_IOS: boolean = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    public static RESULT_DIAMETER: number = 0;
	private static DOCUMENT_TOUCH: DocumentTouch = (window as any).DocumentTouch;
	public static IS_ANDROID: boolean = navigator.userAgent.toLowerCase().indexOf('android') > -1;
	public static IS_SAFARI: boolean = Globals.browser.getBrowserName() === 'Safari';

	public static winW: number = window.innerWidth;
	public static BP_HEIGHT: number = 500;
	public static menuWidth: number = Globals.winW < 768 ? 0 : 80;
	public static winH: number = window.innerHeight;
	public static blobScaleFactor: number = Globals.winW / 1440;
    // public static DEBUG_PANE = Globals.DEBUG ? new Pane({title: 'DEBUG'}) : null;
    public static NOTIFICATIONS_UI = new NotificationsUI();
    public static BLOB_NAMES:string[] = [];
    public static STATE_MANAGER:StateManager;
    public static MORPH_OFFSET_FACTOR = 0.3;
    public static BLOB_AUDIO_PLAYER = new BlobAudioPlayer();
    public static SIGNAL_SAVED_SONG_RESULTS_CHANGES:Signal<IAPIResponse[]> = new Signal();
    public static SAVED_SONG_RESULTS:IAPIResponse[] = [];
    public static MouseMoveTracker = new MouseMoveTracker();

    public static USE_DEBUG_WS_URL: boolean = false;
}
