export class NotificationsUI {
    private container = document.querySelector('.NotificationsUI');
    private dialogs = Array.from(document.querySelectorAll('.NotificationUI-dialog'));
    private vocalBurstDialog: HTMLElement = document.querySelector('.NotificationUI-dialog.noVocalBurst');
    private shareDialog = document.querySelector('.NotificationUI-dialog.share');
    private micDialogTryAgain = document.querySelector('.NotificationUI-dialog.micError button');
    private micDialogMessage = document.querySelector('.NotificationUI-dialog.micError .message');
    private currDialog = -1;

    public static REQUEST_MIC_ACCESS:number = 0;
    public static RE_REQUEST_MIC_ACCESS:number = 1;

    private currCallback:() => void = null;

    constructor() {
        this.dialogs.forEach((dialog, index) => {
           const button = dialog.querySelector('button');
           if (button) {
               // @ts-ignore
               dialog.button = button;
               button.addEventListener('click', () => {
                   if (this.currCallback) {
                       this.currCallback();
                   }
               })
           }
        });

        this.micDialogTryAgain.addEventListener('click', () => {
            location.reload();
        });
    }

    private noVocalBurstVisible = false;
    public toggleNoVocalBurstDialog = (visible:boolean) => {
        if (visible && !this.noVocalBurstVisible) {
            this.noVocalBurstVisible = true;
            //this.vocalBurstDialog.style.display = 'flex';
            this.vocalBurstDialog.classList.add('visible');
        } else if (this.noVocalBurstVisible) {
            this.noVocalBurstVisible = false;
            //this.vocalBurstDialog.style.display = 'none';
            this.vocalBurstDialog.classList.remove('visible');
        }
    }
    private shareVisible = false;
    public toggleShareDialog = (visible:boolean) => {
        if (visible && !this.shareVisible) {
            this.shareVisible = true;
            this.showDialog(3)
        } else if (this.shareVisible) {
            this.shareVisible = false;
            this.showDialog(-1)
        }
    }

    public hideDialogs = () => {
        this.dialogs.forEach((dialog) => {
            dialog.classList.remove('visible');
        });
    }

    public showDialog = (index: number, callback?: () => void, micDialogMessage?:string) => {
        if (micDialogMessage) {
            this.micDialogMessage.innerHTML = micDialogMessage;
        }
        if (index !== this.currDialog) {
            if (this.currDialog >= 0) {
                this.dialogs[this.currDialog].classList.remove('visible');
                this.currDialog = -1;
                this.currCallback = null;
            } else {
                this.container.classList.add('show');
            }
            if (index >= 0) {
                this.currCallback = callback;
                this.currDialog = index;
                this.dialogs[index].classList.add('visible');
            } else {
                this.container.classList.remove('show');
                this.currCallback = null;
            }
        }
    }
}