export class AudioAnalyser {
    private audioCtx: AudioContext;
    private dataArray: Uint8Array;
    private source: MediaStreamAudioSourceNode;
    private analyser: AnalyserNode;

    constructor() {

    }

    setMediaStream = (mediaStream: MediaStream) => {
        console.log('set stream');
        // @ts-ignore
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        this.analyser = this.audioCtx.createAnalyser();

        this.analyser.minDecibels = -90;
        this.analyser.maxDecibels = -10;
        this.analyser.fftSize = 256;
        var bufferLength = this.analyser.frequencyBinCount;
        console.log(bufferLength);
        this.dataArray = new Uint8Array(bufferLength);

        this.source = this.audioCtx.createMediaStreamSource(mediaStream);
        this.source.connect(this.analyser);
    }

    public getFrequencyData() {
        this.analyser.getByteFrequencyData(this.dataArray);
        return this.dataArray;
    }

    public getAverageFrequency() {
        if (!this.dataArray) {
            return 0;
        }

        let value = 0;
        const data = this.getFrequencyData();

        for (let i = 0; i < data.length; i++) {

            value += data[i];

        }

        return value / data.length;

    }
}