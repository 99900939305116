import {blobToBase64} from "../utils/Helpers";
import {Globals} from "../utils/Globals";
import {NotificationsUI} from "./NotificationsUI";

import {MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions} from 'mic-check';
import {RecordingUI} from "./RecordingUI";
import {ResultUI} from "./ResultUI";
import {requestAudioPermissions} from "mic-check/lib/requestMediaPermissions";
import {StateManager} from "./StateManager";
import {DiscreteMediaRecorder} from "./DiscreteMediaRecorder"

const settingsDataByOS = {
    macOS: {
        name: 'System Preferences',
        link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera',
    },
};

const defaultErrorMessage = `<p>Oops, you’ll need to use your microphone for this experience.<span class="hide-safari"><br /><br />Click the <svg width="70" height="79" viewBox="0 0 70 79" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="30" y="39" width="40" height="40" rx="8" fill="#CB5040"/><path d="M26.04 0C19.943 0 15 5.12 15 11.437v23.35c0 5.478 3.717 10.056 8.68 11.176 1.022.23 1.9-.65 1.9-1.734v-6.105c0-2.106 1.648-3.813 3.68-3.813h6.9c1.016 0 1.84-.853 1.84-1.906V11.437C38 5.121 33.057 0 26.96 0h-.92Z" fill="#C4C4C4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M41.548 50.548a1.87 1.87 0 0 1 2.645 0L50 56.355l5.807-5.807a1.87 1.87 0 0 1 2.645 2.645L52.645 59l5.807 5.808a1.87 1.87 0 0 1-2.645 2.644L50 61.645l-5.807 5.807a1.87 1.87 0 0 1-2.645-2.644L47.355 59l-5.807-5.807a1.87 1.87 0 0 1 0-2.645Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.315 31c1.278 0 2.315 1.056 2.315 2.36 0 2.88 1.336 7.887 4.378 12.15 2.985 4.181 7.443 7.434 13.677 7.434H25V70.64c0 1.304-1.036 2.36-2.315 2.36-1.278 0-2.315-1.056-2.315-2.36V57.541c-6.86-.732-11.837-4.676-15.105-9.255C1.697 43.285 0 37.322 0 33.36 0 32.056 1.036 31 2.315 31Z" fill="#C4C4C4"/></svg> microphone blocked icon in your browser's address bar.</span></p>`

export interface IAPIResponse {
    warning?:string;
    predictions?:      { [key: string]: number };
    spotifyTrackInfo?: SpotifyTrackInfo;
}

export interface SpotifyTrackInfo {
    album:             Album;
    artists:           Artist[];
    available_markets: string[];
    disc_number:       number;
    duration_ms:       number;
    explicit:          boolean;
    external_ids:      ExternalIDS;
    external_urls:     ExternalUrls;
    href:              string;
    id:                string;
    is_local:          boolean;
    name:              string;
    popularity:        number;
    preview_url:       string;
    track_number:      number;
    type:              string;
    uri:               string;
}

export interface Album {
    album_type:             string;
    artists:                Artist[];
    available_markets:      string[];
    external_urls:          ExternalUrls;
    href:                   string;
    id:                     string;
    images:                 Image[];
    name:                   string;
    release_date:           string;
    release_date_precision: string;
    total_tracks:           number;
    type:                   string;
    uri:                    string;
}

export interface Artist {
    external_urls: ExternalUrls;
    href:          string;
    id:            string;
    name:          string;
    type:          string;
    uri:           string;
}

export interface ExternalUrls {
    spotify: string;
}

export interface Image {
    height: number;
    url:    string;
    width:  number;
}

export interface ExternalIDS {
    isrc: string;
}


export class RecordingAPI {
    private mediaRecorder: DiscreteMediaRecorder;
    private webSocket: WebSocket;
    public mediaStream: MediaStream;
    private onStream: (stream: MediaStream) => void;
    private resultUI: ResultUI;

    private trackedSessionResults:IAPIResponse[] = [];
    private recordingStartTime:number = 0;
    public mediaRecorderReadyCallback: Function;
    private WAIT_DELAY_FOR_SOCKET_RESPONSE_AFTER_RECORDING_STOPPED = 2500;

    private WS_SENDS = 0;
    private WS_MESSAGES = 0;

    private connectionBlocked = false;
    private inited: Boolean = false;
    private debugAudioElement: HTMLAudioElement;
    private newRecording: boolean = true;

    constructor(resultUI:ResultUI) {
        this.resultUI = resultUI;
        if (Globals.USE_DEBUG_WS_URL) {
            this.debugAudioElement = document.createElement('audio');
            this.debugAudioElement.controls = true;
            this.debugAudioElement.volume = 1;
            document.body.appendChild(this.debugAudioElement);
        }

    }

    public setAudioAnalyserStream = (onStream: (stream: MediaStream) => void) => {
        this.onStream = onStream;

    }

    private onMessage = (event: MessageEvent) => {
        this.WS_MESSAGES++;
        const reconstitutedData = JSON.parse(event.data) as IAPIResponse;
        // console.log('log', reconstitutedData);
        this.trackedSessionResults.push(reconstitutedData);
        if (reconstitutedData.warning) {
            console.log(reconstitutedData.warning);
        } else {
            this.checkPredictions(reconstitutedData);
        }
    }

    private checkPredictions(reconstitutedData:IAPIResponse) {
        const predictions = reconstitutedData.predictions
        // console.log(predictions);
        const cleanedInput = Object.entries(predictions).filter(([emotionName, emotionValue]) => {
/*            if (!Globals.BLOB_NAMES.includes(emotionName.toLowerCase())) {
                console.warn(`Filtered out: ${emotionName} because it's not in the list of emotions we recognize`);
            }*/
            return Globals.BLOB_NAMES.includes(emotionName.toLowerCase()) && emotionValue >= 0.2;
        }).sort(([emotionName, emotionValue], [emotionName2, emotionValue2]) => {
            // @ts-ignore
            return emotionValue2 - emotionValue
        })
        if (cleanedInput.length > 0) {
            console.log(reconstitutedData);
            this.resultUI.setResult(reconstitutedData.spotifyTrackInfo, cleanedInput);
            if (!this.IS_RECORDING) {
                console.log('show results from late predictions, already re-checked session: ', this.recheckedSessionEndedAlready);
                Globals.NOTIFICATIONS_UI.toggleNoVocalBurstDialog(false);
                this.sessionEnded();
            }
            // document.getElementById("music").innerHTML = "<iframe src='https://open.spotify.com/embed/track/" + reconstitutedData.spotifyTrackId + "' width='100%' height='380' frameBorder='0' allowtransparency='true' allow='encrypted-media'></iframe>";
        }
    }

    private onClose = (e: CloseEvent) => {
        if (!this.connectionBlocked) {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(() => {
                this.connect();
            }, 1000);
        }
    }

    private connect = () => {
        if(window.location.href.indexOf('debug') != -1) {
            this.webSocket = new WebSocket("wss://streaming-api-test.hume.ai/v2/vocalBurstStream");
        } else {
            this.webSocket = new WebSocket("wss://vocmimic-websocket.hume.ai/v2/vocalBurstStream");
        }

        //var ws = new WebSocket("ws://127.0.0.1:8080/v2/vocalBurstStream"),
        this.webSocket.onmessage = this.onMessage;
        this.webSocket.onclose = this.onClose;
    }

    private showDialogTimeut: any = 0;

    private setupNewMediaRecorder = () => {
        if (!this.mediaStream) {
            console.warn('No media stream available');
        }
        this.mediaRecorder = DiscreteMediaRecorder.create(this.mediaStream);
        this.mediaRecorder.setOnDataAvailable(async (blobEvent) => {
            console.log('mediaRecorder ondataavailable, sending to server: ', this.IS_RECORDING);
            if(Globals.USE_DEBUG_WS_URL) {
                console.log('play debug sample');
                console.log(this.debugAudioElement);
                this.debugAudioElement.src = URL.createObjectURL(blobEvent.data);
                this.debugAudioElement.play();
            }
            if (this.IS_RECORDING) {
                if (this.webSocket.readyState === WebSocket.OPEN) {
                    // console.log('socket open');
                    const data = await blobToBase64(blobEvent.data)
                    const obj = {
                        "audio": data,
                        "augmentWithTrack": true,
                        "new_audio": this.newRecording
                    }
                    console.log('sending new');
                    const objStr = JSON.stringify(obj)
                    this.webSocket.send(objStr);
                    this.newRecording = false;
                    this.WS_SENDS++;
                } else {
                    this.webSocket.close()
                }
            }
        });
    }

    public initMediaRecorder = () => {
        if (!this.inited) {
            this.inited = true;
            clearTimeout(this.showDialogTimeut);
            this.showDialogTimeut = setTimeout(() => Globals.NOTIFICATIONS_UI.showDialog(NotificationsUI.REQUEST_MIC_ACCESS), 500);
            requestAudioPermissions()
                .then(() => {
                    // can successfully access camera and microphone streams
                    // DO SOMETHING HERE
                    clearTimeout(this.showDialogTimeut);
                    if (Globals.IS_SAFARI && !Globals.IS_IOS) {
                        navigator.mediaDevices.enumerateDevices()
                            .then((deviceInfos) => {
                                let preferredMic: MediaDeviceInfo = null;
                                let foundPreferredMic = false;
                                let audioInputs: MediaDeviceInfo[] = [];
                                deviceInfos.forEach((device) => {
                                    if (device.kind === 'audioinput') {
                                        audioInputs.push(device);
                                        if (device.label.toLowerCase().includes('mac')) {
                                            foundPreferredMic = true;
                                            preferredMic = device;
                                        }
                                    }
                                });
                                if (foundPreferredMic === false) {
                                    preferredMic = audioInputs[0];
                                }
                                if (audioInputs.length === 0) {
                                    console.warn('No microphone input available', deviceInfos, preferredMic, audioInputs.length, foundPreferredMic);
                                }
                                console.log('using mic', preferredMic);
                                this.requestMic(preferredMic);
                            })
                            .catch((ee) => {
                                console.log(ee);
                            });
                    } else {
                        this.requestMic();
                    }

                })
                .catch((err: MediaPermissionsError) => {
                    const {type, name, message} = err;
                    console.log('err RecordingAPI.ts');
                    console.log(err);
                    let errorHTML = defaultErrorMessage;
                    if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
                        // browser does not have permission to access camera or microphone
                        var osSettings = settingsDataByOS[Globals.browser.getOSName()];
                        errorHTML = `<p>Can't use your microphone. Your browser might not have access to your microphone. <br /><br />To fix this problem, open ${settingsDataByOS[Globals.browser.getOSName()] ? `<a onClick="javascript:window.open('${osSettings.link}', '_blank')">${osSettings.name}</a>` : 'Settings.'}</p>`
                    } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
                        // user didn't allow app to access camera or microphone
                        errorHTML = defaultErrorMessage;
                    } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
                        // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
                        // (mostly Windows specific problem)
                        errorHTML = `Can't start your microphone. <br /><br />Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might already be using your microphone. Please turn off other microphones before proceeding.`;
                    } else {
                        // not all error types are handled by this library
                    }
                    clearTimeout(this.showDialogTimeut);
                    Globals.NOTIFICATIONS_UI.showDialog(NotificationsUI.RE_REQUEST_MIC_ACCESS, this.initMediaRecorder, errorHTML);
                });
        } else {
            this.connectionBlocked = false;
            this.connect();
            this.mediaRecorderReadyCallback();
        }
    }

    private requestMic = (device:MediaDeviceInfo = null) => {
        const constraints = device ? {audio: {deviceId: device.deviceId}, video: false} : {audio: true, video: false};
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            Globals.NOTIFICATIONS_UI.showDialog(-1);
            this.mediaStream = stream;
            if (!Globals.IS_IOS && !Globals.IS_SAFARI) this.onStream(stream);
            this.setupNewMediaRecorder();
            this.mediaRecorderReadyCallback();

        }).catch((error) => {
            clearTimeout(this.showDialogTimeut);
            Globals.NOTIFICATIONS_UI.showDialog(NotificationsUI.RE_REQUEST_MIC_ACCESS, this.initMediaRecorder, defaultErrorMessage);
        });
    }


    private IS_RECORDING = false;
    public startRecording = () => {
        // console.log('startRecording', this.IS_RECORDING);
        if (this.mediaStream && this.IS_RECORDING === false) {
            this.IS_RECORDING = true;
            this.newRecording = true;
            this.trackedSessionResults = [];
            Globals.NOTIFICATIONS_UI.toggleNoVocalBurstDialog(false);
            this.recordingStartTime = new Date().getTime();
            this.recheckedSessionEndedAlready = false;
            // this.setupNewMediaRecorder();
            console.log('------- START RECORDING ------');
            if (!this.webSocket) {
                this.connect();
            }
            if (!this.mediaRecorder.isActive()) {
                if (Globals.USE_DEBUG_WS_URL) {
                        this.mediaRecorder.start();
                } else {
                    this.mediaRecorder.start(1000);
                }
            }
        } else if (!this.mediaRecorder) {
            console.log('startRecording, Error: MediaRecorder not avaiable.')
        }/* else {
            console.log('Did not start recording for some reason', this.mediaRecorder.state);
        }*/
    }
    private sessionEndedTimeout:any = 0;

    public stopRecording = (skipResults = false) => {
        console.log('stopRecording mediaRecorder.isActive() = ', this.mediaRecorder.isActive);
        if (this.mediaStream && this.IS_RECORDING) {
            this.IS_RECORDING = false;
            if (Globals.USE_DEBUG_WS_URL) {
                this.mediaRecorder.stop();
            }
            // this.webSocket.close();
            console.log(`Send messages: ${this.WS_SENDS}, received messages: ${this.WS_MESSAGES}, match:`, this.WS_SENDS === this.WS_MESSAGES);
            clearTimeout(this.sessionEndedTimeout);
            if (skipResults === false) {
                Globals.NOTIFICATIONS_UI.toggleNoVocalBurstDialog(false);
                if (this.returnPredictions()) {
                    this.sessionEnded();
                } else {
                    this.sessionEndedTimeout = setTimeout(this.sessionEnded, this.WAIT_DELAY_FOR_SOCKET_RESPONSE_AFTER_RECORDING_STOPPED);
                }
            }
        } else if (!this.mediaRecorder) {
            console.log('stopRecording, Error: MediaRecorder not avaiable.')
        }
    }

    public closeConnection = () => {
        this.connectionBlocked = true;
        if (this.webSocket) {
            this.webSocket.close();
        }
    }

    private returnPredictions = () => {
        if (this.trackedSessionResults.length > 0) {
            if (StateManager.ACTIVE_STEP === StateManager.STEP_TRY_YOUR_OWN) {
                return this.trackedSessionResults.reverse().find((result => result.predictions));
            }
        }
        return null;
    }


    private recheckedSessionEndedAlready = false;
    private sessionEnded = () => {
        const elapsedRecordingTime = new Date().getTime() - this.recordingStartTime;
        console.log(`Recorded for: ${elapsedRecordingTime}ms, Send messages: ${this.WS_SENDS}, received messages: ${this.WS_MESSAGES}, match:`, this.WS_SENDS === this.WS_MESSAGES);
        if (this.trackedSessionResults.length > 0) {
            if (StateManager.ACTIVE_STEP === StateManager.STEP_TRY_YOUR_OWN) {
                var lastPredictionResult = this.returnPredictions();
                console.log("Session ended! Prediction result was:", lastPredictionResult);
                if (!lastPredictionResult) {
                    Globals.NOTIFICATIONS_UI.toggleNoVocalBurstDialog(true);
                } else {
                    // this.checkPredictions(lastPredictionResult);
                    console.log(StateManager.ACTIVE_STEP)
                    Globals.STATE_MANAGER.showRecordingResult(lastPredictionResult);
                    console.log(Object.entries(lastPredictionResult.predictions).length);
                }
            }
        } else if (!this.recheckedSessionEndedAlready && elapsedRecordingTime >= 1000 + this.WAIT_DELAY_FOR_SOCKET_RESPONSE_AFTER_RECORDING_STOPPED) {
            console.log('No predictions received, re-checking in 1 second');
            this.recheckedSessionEndedAlready = true;
            setTimeout(this.sessionEnded, 1000);
        } else if (elapsedRecordingTime >= 500) {
            Globals.NOTIFICATIONS_UI.toggleNoVocalBurstDialog(true);
        }
    }
}