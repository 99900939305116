export class BlobAudioPlayer {
    audioElement: HTMLAudioElement = document.createElement('audio');

    constructor() {

    }

    public playSound(filename: string) {
        try {
            this.audioElement.src = 'https://experience.hume.ai/sound/BurstExamples128/' + filename;
            //this.audioElement.src = '/sound/BurstExamples128/' + filename;
            this.audioElement.play();
        } catch (e) {
            console.log(e);
        }
    }

    public stopSound =() => {
        if (this.audioElement.src && this.audioElement.src !== '' && !this.audioElement.paused) {
            this.audioElement.pause();
        }
    }
}
