import MotionPathPlugin from 'gsap/MotionPathPlugin';
import {Graphics} from "pixi.js";
import gsap from "gsap";
const seedrandom = require('seedrandom');

// https://thewebdev.info/2021/02/27/how-to-detect-a-touch-screen-device-using-javascript/
export function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
      // @ts-ignore
      (navigator.msMaxTouchPoints > 0));
}

export function weightedRandomFromArray(collection:any[], ease:string, seed:number = 1) {
    return gsap.utils.pipe(
        seedrandom(seed)(),            //random number between 0 and 1
        gsap.parseEase(ease),   //apply the ease
        gsap.utils.mapRange(0, 1, -0.5, collection.length-0.5), //map to the index range of the array, stretched by 0.5 each direction because we'll round and want to keep distribution (otherwise linear distribution would be center-weighted slightly)
        gsap.utils.snap(1),     //snap to the closest integer
        i => collection[i]      //return that element from the array
    );
}
export function weightedRandom(ease:string, seed:number = 1):number {
    // @ts-ignore
    return gsap.utils.pipe(
        seedrandom(seed),            //random number between 0 and 1
        gsap.parseEase(ease)
    );
}

export function debounce_leading(func, timeout = 300){
    let timer;
    return (...args) => {
        if (!timer) {
            func.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
        }, timeout);
    };
}
export function pointsInPath(rawPath:gsap.plugins.RawPath, numPoints = 10) {
    const step = 1 / numPoints;
    const points = [];
    MotionPathPlugin.cacheRawPathMeasurements(rawPath, 50);

    for (let i = 0; i <= 1; i += step) {
        points.push(MotionPathPlugin.getPositionOnPath(rawPath, i));
    }

    return points;
}
export function formatPoints(points:any[], close:boolean, modify = true, duplicateFirstPoint = false) {
    if (modify) {
        points = [...points];
    }

    if (modify) {
        if (!Array.isArray(points[0])) {
            points = points.map(({x, y}) => [x, y]);
        }
    }

    if (close) {
        const lastPoint = points[points.length - 1];
        const secondToLastPoint = points[points.length - 2];

        const firstPoint = points[0];
        const secondPoint = points[1];

        points.unshift(lastPoint);
        points.unshift(secondToLastPoint);

        points.push(firstPoint);
        points.push(secondPoint);
    }
    if (duplicateFirstPoint) {
        const firstPoint = points[0];
        const secondPoint = points[1];
        points.push(firstPoint);
        points.push(secondPoint);
    }

    if (modify) {
        return points.flat();
    } else {
        return points;
    }
}

export function drawSpline(points = [], tension = 1, gfx:Graphics, gfx2:Graphics) {
    // points = formatPoints(points, true);

    const size = points.length;
    const last = size - 4;

    const startPointX = points[2];
    const startPointY = points[3];

    // let path = "M" + [startPointX, startPointY];

    gfx.moveTo(startPointX, startPointY);
    gfx2.moveTo(startPointX, startPointY);
    // cb && cb("MOVE", [startPointX, startPointY]);

    const startIteration = 2;
    const maxIteration = size - 6;
    const inc = 2;

    for (let i = startIteration; i < maxIteration; i += inc) {
        const x0 = i ? points[i - 2] : points[0];
        const y0 = i ? points[i - 1] : points[1];

        const x1 = points[i];
        const y1 = points[i + 1];

        const x2 = points[i + 2];
        const y2 = points[i + 3];

        const x3 = i !== last ? points[i + 4] : x2;
        const y3 = i !== last ? points[i + 5] : y2;

        const cp1x = x1 + ((x2 - x0) / 6) * tension;
        const cp1y = y1 + ((y2 - y0) / 6) * tension;

        const cp2x = x2 - ((x3 - x1) / 6) * tension;
        const cp2y = y2 - ((y3 - y1) / 6) * tension;

        // path += "C" + [cp1x, cp1y, cp2x, cp2y, x2, y2];

        gfx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x2, y2);
        gfx2.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x2, y2);
        // cb && cb("CURVE", [cp1x, cp1y, cp2x, cp2y, x2, y2]);
    }

    // return path;
}
export function drawSplineClosed(points = [], tension = 1, gfx:Graphics, gfx2:Graphics) {
    // points = formatPoints(points, close);

    const size = points.length;
    const last = size - 4;

    const startPointX = points[2];
    const startPointY = points[3];

    // let path = "M" + [startPointX, startPointY];

    gfx.moveTo(startPointX, startPointY);
    gfx2.moveTo(startPointX, startPointY);
    // cb && cb("MOVE", [startPointX, startPointY]);

    const startIteration = 2;
    const maxIteration = size - 4;
    const inc = 2;

    for (let i = startIteration; i < maxIteration; i += inc) {
        const x0 = i ? points[i - 2] : points[0];
        const y0 = i ? points[i - 1] : points[1];

        const x1 = points[i];
        const y1 = points[i + 1];

        const x2 = points[i + 2];
        const y2 = points[i + 3];

        const x3 = i !== last ? points[i + 4] : x2;
        const y3 = i !== last ? points[i + 5] : y2;

        const cp1x = x1 + ((x2 - x0) / 6) * tension;
        const cp1y = y1 + ((y2 - y0) / 6) * tension;

        const cp2x = x2 - ((x3 - x1) / 6) * tension;
        const cp2y = y2 - ((y3 - y1) / 6) * tension;

        // path += "C" + [cp1x, cp1y, cp2x, cp2y, x2, y2];

        gfx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x2, y2);
        gfx2.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x2, y2);
        // cb && cb("CURVE", [cp1x, cp1y, cp2x, cp2y, x2, y2]);
    }

    // return path;
}

export  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        // @ts-ignore
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(blob);
    });
}

//  A formatted version of a popular md5 implementation.
//  Original copyright (c) Paul Johnston & Greg Holt.
//  The function itself is now 42 lines long.

export function md5(inputString) {
    var hc="0123456789abcdef";
    function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
    function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
    function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
    function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
    function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
    function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
    function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
    function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
    function sb(x) {
        var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
        for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
        blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
    }
    var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
    for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
        a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
        b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
        c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
        d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
        a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
        b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
        c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
        d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
        a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
        b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
        c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
        d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
        a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
        b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
        c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
        d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
        a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
        b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
        c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
        d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
        a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
        b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
    }
    return rh(a)+rh(b)+rh(c)+rh(d);
}
