import {Blob} from './Blob';
import {SongUI} from "./SongUI";
import {SpotifyTrackInfo} from "./RecordingAPI";
import {Globals} from "../utils/Globals";
import gsap from "gsap";

export class ResultUI {
    public blobs: Blob[];

    private container = document.querySelector('.ResultUI');
    private resultItems = Array.from(document.querySelectorAll('.ResultUI .ResultUI-item'));
    private resultItemLabels = Array.from(document.querySelectorAll('.ResultUI .ResultUI-label'));
    private resultItemValues = Array.from(document.querySelectorAll('.ResultUI .ResultUI-value span'));
    private resultItemPaths = Array.from(document.querySelectorAll('.ResultUI .ResultUI-value svg path') as NodeListOf<SVGPathElement>);
    private predictions: [string, unknown][];
    public visiblePredictions: [string, unknown][];
    private highlightedBlobs: Blob[];
    public songUI = new SongUI();
    private spotifyTrackInfo: SpotifyTrackInfo;

    constructor() {
    }

    public setResult = (spotifyTrackInfo: SpotifyTrackInfo, predictions: [string, unknown][]) => {
        console.log(predictions);
        this.highlightedBlobs = [];
        this.blobs.forEach((blob, index) => {
            if (predictions.find((result) => result[0].toLowerCase() === blob.nameLowercased)) {
                blob.isHighlighted = false;
                blob.highlighted(true);
                this.highlightedBlobs.push(blob);
            } else {
                blob.highlighted(false);
            }
        });
        this.spotifyTrackInfo = spotifyTrackInfo;
        this.predictions = predictions;
        this.visiblePredictions = this.predictions.slice(0, Math.min(this.predictions.length, 4));
    }

    public clearResults = () => {
        this.highlightedBlobs = [];
        this.blobs.forEach((blob, index) => {
            blob.isHighlighted = true;
            blob.highlighted(false);
        });
    }

    private currResultClass = null;
    showCurrentResult = () => {
        Globals.STATE_MANAGER.transitioning = true;
        this.resultItems.forEach((item) => {
            item.classList.remove('visible');
            item.classList.remove('hidden');
        });
        if (this.currResultClass) {
            this.container.classList.remove(this.currResultClass);
        }
        this.currResultClass = 'results-' + this.predictions.length;
        this.container.classList.add(this.currResultClass);
        let totalPredictionAmount = 0;
        this.visiblePredictions.forEach((prediction) => {
            totalPredictionAmount += prediction[1] as number;
        })
        this.visiblePredictions.forEach((prediction, index) => {
            this.resultItemPaths[index].style.fill = this.highlightedBlobs.find(blob => prediction[0].toLowerCase() === blob.nameLowercased).fillString;
            this.resultItemLabels[index].textContent = prediction[0] === 'Surprise (positive)' ? 'Surprise' : prediction[0];
            this.resultItemValues[index].textContent = (((prediction[1] as number) / totalPredictionAmount) * 100).toFixed(0) + "%"
            this.resultItems[index].classList.add('visible');
        });
        this.songUI.setSongInfo(this.spotifyTrackInfo);

        gsap.delayedCall(2.8, () => {
            Globals.STATE_MANAGER.transitioning = false;
        });
    }

    public saveResultToLocalStorage = () => {
        var prevResults = localStorage.getItem('SavedResults');
        if (prevResults) {
            prevResults = JSON.parse(prevResults);
        }
        var savedResults:any[] = Array.isArray(prevResults) ? prevResults : [];
        const result = {predictions: this.visiblePredictions, spotifyTrackInfo: this.spotifyTrackInfo};
        savedResults.unshift(result);
        // savedResults.length = Math.min(savedResults.length, 50); //Limit to only save 50 latest.
        var saveObject = JSON.stringify(savedResults);
        localStorage.setItem('SavedResults', saveObject);
        Globals.SAVED_SONG_RESULTS = savedResults;
        Globals.SIGNAL_SAVED_SONG_RESULTS_CHANGES.emit(savedResults);
    }

    showAlbumResult = () => {
        this.songUI.showSongInfo();
    }

    hideCurrentResult = () => {
        this.resultItems.forEach((item) => {
            item.classList.add('hidden');
            item.classList.remove('visible');
        });
        this.songUI.hideSongInfo();
    }
}