import {Globals} from "./utils/Globals";
import {StateManager} from "./components/StateManager";
import {Menu} from "./components/Menu";
import {Router} from "./components/Router";
import {md5} from "./utils/Helpers";
import {isTouchDevice} from "./utils/Helpers";

class Main {
    private router: Router;

    private stateManager: StateManager;
    private menu: Menu;

    constructor() {
        if (Globals.IS_SAFARI) {
            document.documentElement.classList.add('is-safari');
        }
        if (isTouchDevice()) {
            document.documentElement.classList.add('is-touch');
        }
        this.resize();
        window.addEventListener('resize', this.resize);


        const urlParams = new URLSearchParams(window.location.search);
        const isDebug = urlParams.get('debug');
        if (isDebug) {
            Globals.USE_DEBUG_WS_URL = true;
        }

        this.initialize();
        const layer: HTMLElement = document.querySelector('#disableLayer');
        layer.style.display = 'none';

        // Hotfix for Audio not playing on hover
        document.body.onclick = function() {
            new Audio(
                'https://experience.hume.ai/sound/blank.mp3'
                //'/sound/blank.mp3'
            )
                .play()
                .catch((e) => {
                    console.log(e);
                });

            document.body.onclick = null;
        }

    }

    initialize = () => {
        this.menu = new Menu();
        this.router = new Router(this.menu);
        this.stateManager = new StateManager(this.router, () => {
            document.body.classList.remove('preload');
            let loadingLayer = document.getElementById('LoadingCoverLayer');
            loadingLayer.style.opacity = '0';
            setTimeout(() => {
                document.body.removeChild(loadingLayer);
                loadingLayer = null;
            }, 800);
        });
    }

    resize = () => {
        Globals.winW = window.innerWidth;
        Globals.winH = window.innerHeight;
        Globals.blobScaleFactor = Globals.winW < 768 ? Globals.winW / 768 : (Globals.winW <= 1024 ? Globals.winW / 1024 : Globals.winW / 1440);
        if (Globals.winH < Globals.BP_HEIGHT) {
            Globals.blobScaleFactor *= 0.8;
        }
        Globals.menuWidth = Globals.winW < 768 ? 0 : 80;
    }
}

window.onload = () => {
    // @ts-ignore

    window.Main = new Main();
};
