export const BLOBS_SVG_FREE_ORIENTATION = `<svg width="255" height="235" viewBox="0 0 255 235" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Joy"
          d="M25.8837 165.928C39.8837 185.548 80.4837 202.078 104.434 204.668C129.864 207.418 154.434 194.878 175.194 179.898C203.264 159.608 229.354 129.508 226.734 94.9684C225.024 72.3384 210.784 52.0483 192.674 38.3783C174.564 24.7083 152.794 16.7984 131.074 10.2284C110.274 3.93841 88.6937 -1.35163 67.0237 0.308367C24.9237 3.54837 0.733713 41.9284 0.0237131 84.0884C-0.536287 113.678 8.84373 142.008 25.8837 165.928Z"
          fill="#9DC8D6"/>
    <path id="Triumph"
          d="M12.787 21.818C6.33333 29.9777 2.14028 39.6919 0.628971 49.985C-0.882337 60.278 0.341288 70.7875 4.17698 80.458C8.94698 92.088 17.357 101.868 22.897 113.158C29.217 126.058 31.897 140.988 40.967 152.098C50.317 163.488 65.287 169.038 79.967 170.198C111.117 172.638 142.967 155.908 158.667 128.878C174.367 101.848 173.057 65.878 155.477 40.048C143.327 22.198 123.827 9.81802 102.777 5.40802C84.437 1.55802 63.667 -2.47199 45.137 1.89801C32.4561 4.79877 21.0856 11.8003 12.787 21.818V21.818Z"
          fill="#ED8535"/>
    <path id="Satisfaction"
          d="M206.502 1.95639C189.644 -1.96592 171.948 0.0472674 156.402 7.65635C125.892 22.7163 107.312 57.6564 74.8221 67.6564C62.1621 71.5764 48.602 71.2464 35.552 73.5464C22.502 75.8464 8.84205 81.7464 2.93205 93.6164C-2.06795 103.696 -0.287894 116.216 5.36211 125.956C11.0121 135.696 20.0321 143.066 29.5021 149.166C56.2167 166.311 87.471 175.021 119.202 174.166C150.928 173.17 181.621 162.655 207.292 143.986C225.082 130.986 240.292 113.846 248.292 93.3464C255.256 75.6751 255.906 56.1415 250.132 38.0464C244.012 18.4064 226.422 6.66639 206.502 1.95639Z"
          fill="#D17EDF"/>
    <path id="Calmness"
          d="M41.5163 32.636C20.7863 46.946 4.44628 68.826 0.726276 93.736C-2.99372 118.646 7.72637 146.156 29.4464 158.866C47.7564 169.556 71.0463 168.716 90.9663 161.456C110.886 154.196 128.126 141.176 144.776 128.046C162.396 114.136 180.246 99.276 190.356 79.236C200.466 59.196 200.886 32.546 185.456 16.236C152.486 -18.544 74.2863 10.006 41.5163 32.636Z"
          fill="#AAD3F0"/>
    <path id="Interest"
          d="M124.018 19.4706C103.618 4.70061 77.5379 -3.41935 52.8179 1.38065C28.0979 6.18065 5.7179 25.3806 0.987903 50.1706C-3.0121 71.0006 5.5579 92.6807 19.0579 109.041C32.5579 125.401 50.5579 137.311 68.4879 148.631C87.4879 160.631 107.488 172.471 129.698 175.331C151.908 178.191 177.228 169.731 187.458 149.731C209.288 107.031 156.278 42.8206 124.018 19.4706Z"
          fill="#D1E9F1"/>
    <path id="Distress"
          d="M3.40289 101.107C-2.89711 126.967 -1.07708 155.997 14.1829 177.807C29.4429 199.617 60.0629 211.427 84.1829 200.207C101.743 192.047 112.583 174.307 121.453 157.097C130.323 139.887 138.823 121.497 154.253 109.807C168.163 99.2774 186.973 94.8074 197.353 80.8074C207.733 66.8074 206.143 46.2474 196.453 31.8074C186.763 17.3674 170.453 8.36739 153.503 4.12739C117.043 -5.03261 84.7529 0.877392 54.9229 23.6274C29.8029 42.7074 10.9029 70.3074 3.40289 101.107Z"
          fill="#F7E99D"/>
    <path id="Craving"
          d="M76.1835 172.933C109.303 182.543 146.183 177.333 175.993 159.933C205.803 142.533 228.213 113.353 238.523 80.4425C243.423 64.8325 245.653 47.8025 240.883 32.1625C236.113 16.5225 223.183 2.65254 206.983 0.35254C192.413 -1.71746 177.983 5.59254 166.983 15.3525C155.983 25.1125 147.423 37.3525 137.203 47.9125C126.983 58.4725 114.263 67.8125 99.6234 69.2125C81.4734 70.9525 64.1535 60.3225 45.9235 60.3625C24.7035 60.3625 2.76341 79.4825 0.243413 98.9625C-1.14659 109.693 3.7334 118.773 6.8534 124.583C8.5334 127.713 14.5634 137.033 36.4034 152.583C45.5434 159.073 59.1135 167.973 76.1835 172.933Z"
          fill="#545C1E"/>
    <path id="Tiredness"
          d="M64.3752 0.161807C43.4752 -0.998193 16.6552 3.71182 4.7052 26.3018C-4.4448 43.6018 0.705197 63.7118 11.9552 74.3018C23.2052 84.8918 39.1852 88.0918 54.5952 91.0918L139.645 107.632C151.175 109.872 163.075 112.102 175.485 108.902C187.895 105.702 201.085 95.6618 205.575 81.9518C225.925 19.7518 94.9552 1.86181 64.3752 0.161807Z"
          fill="#125475"/>
    <path id="Boredom"
          d="M42.2113 5.17966C24.2113 11.1097 6.71129 22.9098 1.87129 41.6698C-2.96871 60.4298 2.04127 80.6697 11.6013 97.4997C21.1613 114.33 34.9813 128.32 48.9513 141.73C62.9213 155.14 77.3413 168.33 88.5213 184.15C97.5213 196.87 104.521 211.39 116.471 221.39C132.961 235.17 157.261 237.86 177.321 230.16C197.381 222.46 213.101 205.36 221.461 185.56C229.821 165.76 231.251 143.49 228.091 122.23C222.211 82.6697 200.091 45.5598 167.361 22.5298C134.631 -0.500242 80.8713 -5.08034 42.2113 5.17966"
          fill="#6455BB"/>
    <path id="Disappointment"
          d="M41.2568 67.9841C44.1268 69.9841 46.6168 72.574 49.7768 74.054C56.3568 77.114 64.3468 74.5541 70.0768 70.1141C75.8068 65.6741 79.9768 59.5641 84.9368 54.2641C89.8968 48.9641 96.1968 44.264 103.447 43.934C115.177 43.444 125.197 54.574 136.897 53.574C147.447 52.674 154.527 42.4041 158.587 32.6341C162.647 22.8641 165.757 11.884 174.137 5.42402C180.567 0.424018 189.287 -0.915958 197.267 0.574042C205.247 2.06404 212.527 6.19412 218.847 11.2741C226.807 17.7553 233.394 25.7587 238.224 34.8161C243.054 43.8735 246.03 53.8031 246.977 64.0241C247.815 74.2548 246.606 84.55 243.419 94.3078C240.232 104.066 235.132 113.09 228.417 120.854C215.417 135.674 197.277 144.924 178.787 151.674C145.407 163.864 108.497 168.954 74.1568 159.824C50.7668 153.604 28.9368 140.194 13.7468 121.244C1.55681 106.054 -7.13324 88.2441 8.19676 71.5041C16.4968 62.4441 31.1968 60.9841 41.2568 67.9841Z"
          fill="#36A2BA"/>
    <path id="Surprise"
          d="M110.776 8.36995C99.8759 16.2599 92.3959 27.97 86.0459 39.83C79.6959 51.69 74.1159 64.0899 65.8559 74.7199C54.5059 89.3299 38.7259 99.72 25.1859 112.38C11.6459 125.04 -0.304099 141.52 0.00590062 160.03C0.395901 183.43 21.2959 202.8 44.1559 207.86C67.0159 212.92 90.9059 206.48 112.346 197.08C138.136 185.78 162.346 169.78 179.746 147.62C197.146 125.46 207.046 96.6199 202.996 68.7999C197.476 31.0499 150.476 -20.35 110.776 8.36995Z"
          fill="#C2C450"/>
    <path id="Sympathy"
          d="M101.376 6.08193C90.2758 14.2719 84.1358 27.4219 78.8158 40.1519C73.4958 52.8819 68.2458 66.2119 58.1758 75.6419C41.7158 91.0819 14.3258 94.2219 3.71579 114.082C-3.60422 127.792 0.455775 145.732 10.9558 157.182C21.4558 168.632 37.1558 174.242 52.6458 175.412C68.1358 176.582 83.6458 173.772 98.8458 170.702C118.666 166.702 139.606 161.582 153.646 147.022C163.646 136.602 169.016 122.342 171.006 108.022C172.996 93.7019 171.926 79.1119 170.686 64.6919C169.686 53.4419 168.616 42.0119 164.426 31.5219C155.466 9.08193 123.576 -10.2581 101.376 6.08193Z"
          fill="#818CEF"/>
    <path id="Awe"
          d="M4.18952 47.7888C-9.36049 85.3788 13.8495 119.639 18.9695 127.189C24.7695 135.759 43.7894 163.909 80.6094 169.349C93.4041 171.145 106.439 170.057 118.758 166.164C131.078 162.271 142.371 155.671 151.81 146.849C166.87 132.569 176.46 112.469 177.35 91.7389C177.642 82.468 176.35 73.2152 173.529 64.3788C164.629 36.0588 143.34 20.8689 135.71 15.9989C118.01 4.69886 101.26 2.64882 90.0296 1.39882C75.1296 -0.261176 58.4596 -2.11119 40.5596 6.56881C14.9696 18.9488 5.60952 43.7088 4.18952 47.7888Z"
          fill="#7EB1E1"/>
    <path id="Realization"
          d="M81.8983 155.318C89.2683 157.138 144.898 169.968 191.528 135.458C208.119 123.08 221.23 106.625 229.588 87.6876C236.258 72.3576 243.128 54.5377 236.658 34.1577C231.048 16.5177 215.228 1.78766 196.788 0.15766C177.028 -1.55234 159.078 10.9376 143.788 23.5576C128.498 36.1776 112.448 50.1377 92.7282 52.2877C80.2182 53.6577 67.7882 50.0076 55.4582 47.4976C38.0282 43.9476 18.9382 43.6076 7.68818 59.4976C-2.31182 73.7076 -2.10182 85.8077 5.68818 100.718C11.8582 112.458 36.3883 144.038 81.8983 155.318Z"
          fill="#207EB3"/>
    <path id="Horror"
          d="M86.5605 73.1386C93.7505 62.6586 96.6505 49.8385 102.46 38.5385C111.46 20.9985 127.46 6.68859 146.55 1.76859C165.64 -3.15141 187.55 2.27857 200.68 16.9986C209.26 26.6386 213.68 39.4286 215.25 52.2386C219.32 85.4986 204.75 119.499 180.84 142.959C156.93 166.419 124.58 180.059 91.5405 185.629C76.8705 188.109 61.7305 189.078 47.1605 186.048C32.5905 183.018 18.5505 175.679 9.50046 163.869C0.450462 152.059 -2.92954 135.458 2.87046 121.778C7.64046 110.408 17.9705 102.099 29.2505 97.1386C38.8205 92.9386 49.1204 90.8386 59.4504 89.1386C66.494 88.0132 73.1682 85.2263 78.9204 81.0086C81.8735 78.8128 84.4533 76.1554 86.5605 73.1386V73.1386Z"
          fill="#763082"/>
    <path id="Disgust"
          d="M73.9267 171.729C93.3967 181.729 115.807 186.859 137.387 183.289C158.967 179.719 179.387 166.659 189.227 147.099C199.067 127.539 196.497 101.699 181.067 86.1991C170.857 75.9391 156.637 70.999 144.197 63.609C127.327 53.609 113.757 38.9891 98.8766 26.1891C83.9966 13.3891 66.6667 1.92903 47.1067 0.279033C-31.5133 -6.32097 6.10672 106.039 32.8367 138.389C44.1315 152.173 58.1114 163.516 73.9267 171.729V171.729Z"
          fill="#DFE2CD"/>
    <path id="Excitement"
          d="M6.0493 87.7622C12.5093 103.652 23.6792 117.312 36.6892 128.492C67.2192 154.762 109.859 167.842 149.129 158.912C188.399 149.982 222.409 117.462 229.129 77.7321C232.039 60.4021 229.669 41.7321 219.819 27.1121C209.969 12.4921 191.939 3.11217 174.599 6.01217C157.599 8.89217 143.869 22.5322 126.809 24.9322C97.5793 29.0522 72.6393 -0.687828 43.1293 0.0121725C-1.13074 1.07217 -6.8607 56.0122 6.0493 87.7622Z"
          fill="#F49B28"/>
    <path id="Contempt"
          d="M0.0499878 110.253C-0.780012 95.9426 9.04999 82.6726 21.33 75.3326C33.61 67.9926 48.18 65.4927 62.33 63.4327C76.48 61.3727 91.04 59.5227 103.94 53.2627C128.48 41.3527 144 15.0627 169.15 4.51265C179.032 0.502596 189.787 -0.868427 200.358 0.534139C210.93 1.9367 220.956 6.06463 229.45 12.5127C237.783 19.1709 244.241 27.883 248.188 37.7927C252.134 47.7023 253.434 58.4684 251.96 69.0327C248.89 89.2227 235.76 106.563 220.6 120.243C195.846 142.47 165.223 157.112 132.38 162.423C99.5064 167.605 65.8358 163.228 35.38 149.813C21.86 143.813 2.21 133.713 0 110.223"
          fill="#768830"/>
    <path id="Contemplation"
          d="M1.92528 63.8532C-5.42472 95.5632 8.92528 130.073 33.8053 150.993C58.6853 171.913 92.7353 180.153 125.215 177.853C147.925 176.253 170.645 169.623 189.145 156.333C207.645 143.043 221.565 122.713 224.615 100.143C229.535 63.6333 204.495 27.4032 171.095 11.8532C119.925 -11.9768 17.2853 -2.40676 1.92528 63.8532Z"
          fill="#B1B4FF"/>
    <path id="Confusion"
          d="M3.83659 80.4045C10.2766 66.1345 26.2666 56.8046 41.8366 58.2046C58.7666 59.7246 74.6266 72.4446 91.1466 68.4746C104.817 65.1846 113.227 51.6645 119.147 38.8845C125.067 26.1045 130.597 11.9846 142.567 4.59457C158.787 -5.40543 181.697 1.8845 193.127 17.1445C204.557 32.4045 206.217 53.1445 202.587 71.8845C199.238 88.4838 192.225 104.126 182.062 117.671C171.898 131.215 158.838 142.32 143.837 150.175C128.72 157.808 112.09 161.97 95.1598 162.357C78.2298 162.745 61.4267 159.348 45.9766 152.415C19.7666 140.425 -10.9034 113.035 3.83659 80.4045Z"
          fill="#C76D29"/>
    <path id="Pain"
          d="M31.8243 51.5339C52.8291 25.4857 82.469 7.82244 115.374 1.74393C128.374 -0.61607 142.114 -1.04609 154.564 3.41391C167.014 7.87391 178.004 17.884 181.024 30.764C184.044 43.644 178.774 57.2639 170.024 67.0639C161.274 76.8639 149.394 83.3139 137.354 88.5939C125.314 93.8739 112.814 98.1839 101.354 104.684C80.0711 116.865 63.5849 135.947 54.6243 158.774C51.2643 167.404 48.5542 177.144 40.9542 182.434C31.2042 189.224 16.7443 185.184 9.13434 176.064C-4.34566 159.904 0.134259 133.204 3.55426 114.134C7.63636 91.2445 17.3518 69.7311 31.8243 51.5339V51.5339Z"
          fill="#FFAFC5"/>
    <path id="Relief"
          d="M67.8323 18.9656C52.9523 22.8056 37.8323 26.9656 24.9623 35.3256C12.0923 43.6856 1.48232 56.9656 0.142317 72.2756C-1.29768 88.7556 8.35228 104.806 21.7323 114.546C35.1123 124.286 51.7323 128.546 68.0923 130.726C102.6 135.238 137.687 131.295 170.332 119.236C190.442 111.796 210.132 100.666 222.172 82.9156C234.212 65.1656 236.642 39.6856 223.452 22.7756C216.452 13.7756 205.912 8.1156 194.982 4.9656C175.282 -0.694397 157.412 -1.56438 137.612 2.60562C114.232 7.54562 90.9723 12.9989 67.8323 18.9656Z"
          fill="#FF9782"/>
    <path id="Sadness"
          d="M55.9843 0.890626C41.6443 2.67063 27.1642 6.89061 15.9842 16.1206C4.80419 25.3506 -2.28574 40.2706 0.674257 54.4106C4.15426 71.0306 20.0443 82.2406 36.0643 87.8806C52.0843 93.5206 69.3643 95.3706 84.8643 102.311C101.264 109.661 114.684 122.311 130.134 131.471C145.584 140.631 165.274 146.261 181.344 138.211C197.814 129.961 205.144 109.311 202.704 91.0506C194.374 28.3306 112.054 -6.08937 55.9843 0.890626Z"
          fill="#54A0E5"/>
    <path id="Adoration"
          d="M101.373 6.08936C90.2633 14.2794 84.1233 27.4293 78.8033 40.1593C73.4833 52.8893 68.2434 66.2194 58.1634 75.6494C41.7234 91.0294 14.3234 94.2194 3.71342 114.079C-3.60658 127.789 0.463408 145.729 10.9534 157.179C21.4434 168.629 37.1533 174.239 52.6433 175.409C68.1333 176.579 83.6433 173.769 98.8533 170.699C118.673 166.699 139.603 161.579 153.643 147.019C163.643 136.599 169.013 122.339 171.013 108.019C173.013 93.6994 171.923 79.1093 170.683 64.6893C169.683 53.4393 168.623 42.0094 164.433 31.5194C155.463 9.06941 123.623 -10.2606 101.373 6.08936Z"
          fill="#FFCCDA"/>
    <path id="Fear"
          d="M132.49 12.5228C121.71 22.5828 114.58 35.8229 107.32 48.6529C100.06 61.4829 92.1805 74.4329 80.4405 83.3529C66.9805 93.5929 49.8805 97.4429 34.3205 104.073C18.7605 110.703 3.18043 122.183 0.410428 138.873C-2.18957 154.473 7.88047 170.233 21.6405 178.033C35.4005 185.833 52.0004 186.943 67.7404 185.413C98.4814 182.344 127.589 170.1 151.28 150.273C174.896 130.342 191.969 103.767 200.28 74.003C204.28 59.523 206.09 43.8929 201.46 29.6029C191.21 -1.89707 155.93 -9.34715 132.49 12.5228Z"
          fill="#D2D0FF"/>
    <path id="Anger"
          d="M78.583 42.0846C73.663 52.1546 68.793 62.4446 61.353 70.8246C45.763 88.4046 20.303 95.9946 7.60301 115.765C-1.69699 130.235 -2.20705 149.465 4.66295 165.225C11.5329 180.985 25.163 193.305 40.843 200.365C56.523 207.425 74.133 209.515 91.293 208.365C140.863 204.985 187.473 173.065 208.543 128.065C216.723 110.615 221.263 91.0646 218.543 71.9346C214.543 43.6846 195.443 26.6346 189.073 21.2146C176.697 10.7778 161.697 3.93319 145.703 1.42468C137.703 0.104677 127.123 -1.51543 114.873 2.81457C108.028 5.09014 101.791 8.89272 96.633 13.9346C88.653 21.8246 83.473 32.0846 78.583 42.0846Z"
          fill="#F55051"/>
    <path id="Awkwardness"
          d="M4.81354 75.3342C-0.0749021 91.4047 -1.25937 108.374 1.34894 124.968C3.95725 141.561 10.2906 157.349 19.8736 171.144C29.6704 184.79 42.431 196.039 57.1975 204.048C71.964 212.056 88.3528 216.616 105.134 217.384C131.134 218.384 158.384 208.984 174.674 188.724C190.964 168.464 193.464 136.724 176.884 116.724C169.114 107.334 157.884 100.834 151.884 90.2142C143.254 74.8842 147.884 55.5743 143.664 38.4943C141.839 31.3575 138.488 24.7018 133.84 18.9869C129.192 13.2721 123.359 8.63447 116.744 5.3943C70.6036 -16.9157 17.6035 34.6042 4.81354 75.3342Z"
          fill="#D8E0A8"/>
    <path id="Amusement"
          d="M125.59 200.853C120.537 199.317 115.846 196.779 111.796 193.389C107.746 189.999 104.421 185.827 102.02 181.123C91.9503 161.123 99.7604 134.333 85.8204 116.843C76.9704 105.733 61.9703 101.973 48.0403 99.173C34.1103 96.373 19.1403 93.3429 9.41034 82.9829C-2.08966 70.7429 -2.58966 50.763 5.41034 35.983C13.4103 21.203 28.4103 11.293 44.2803 5.75297C76.3403 -5.45703 113.59 0.0329208 141.44 19.4529C182.53 48.1129 188 94.313 189.28 105.513C191.433 123.692 189.246 142.122 182.9 159.293C178.09 172.023 171.25 190.183 153.41 198.293C150.36 199.703 138.75 204.783 125.59 200.853Z"
          fill="#FFC559"/>
    <path id="Desire" d="M5.29035 120.852C16.4204 158.072 43.8904 189.852 78.5404 207.372C113.19 224.892 154.54 228.372 191.96 218.022C209.72 213.122 227.19 204.832 239.09 190.772C250.99 176.712 256.2 156.002 248.49 139.262C241.56 124.212 225.81 114.992 209.85 110.522C193.89 106.052 177.13 105.422 160.85 102.222C144.57 99.0222 128 92.6723 117.73 79.6623C105.01 63.5423 104.49 40.6623 93.3301 23.4323C80.3301 3.43233 48.8802 -5.73771 28.9002 3.77229C17.9002 9.01229 12.2803 19.1723 8.69025 25.6723C6.75025 29.1823 1.61031 40.5723 0.250312 70.6723C-0.299688 83.3923 -0.439649 101.632 5.29035 120.852Z" fill="#AC4ABD"/>
</svg>`;

export const BLOBS_SVG = `<svg width="285" height="211" viewBox="0 0 285 211" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="Joy" d="M209.357 52.2477C198.44 30.7589 160.76 8.35785 137.464 2.22439C112.729 -4.28803 86.5634 4.44679 63.8012 16.1626C33.0187 32.0386 2.73083 57.9102 0.169457 92.4546C-1.5152 115.087 9.53897 137.274 25.4073 153.492C41.2757 169.71 61.6223 180.779 82.1193 190.515C101.748 199.838 122.298 208.287 143.973 209.878C186.085 212.954 215.73 178.612 222.72 137.029C227.688 107.854 222.638 78.4418 209.357 52.2477Z" fill="#9DC8D6"/>
<path id="Triumph" d="M0 79.8839C0.21408 90.2851 3.16121 100.447 8.54537 109.349C13.9295 118.25 21.5611 125.579 30.6738 130.598C41.7541 136.534 54.4651 138.726 65.9231 143.91C79.0072 149.84 90.5753 159.651 104.642 162.45C119.102 165.286 134.182 160.041 146.244 151.594C171.826 133.654 185.749 100.481 180.66 69.6393C175.57 38.7977 151.67 11.8841 121.672 3.14582C100.94 -2.89203 78.0201 -0.0331612 58.9758 9.96013C42.3784 18.6609 23.7919 28.7691 12.2787 43.9317C4.34254 54.2389 0.0267647 66.8755 0 79.8839Z" fill="#ED8535"/>
<path id="Satisfaction" d="M234.826 15.9536C221.189 5.29385 204.293 -0.33552 186.988 0.0154742C152.973 0.819542 121.402 24.6794 87.723 20.0602C74.5899 18.2816 62.4311 12.2693 49.6269 8.85718C36.8226 5.44502 21.9484 5.04077 11.5876 13.316C2.80618 20.3511 -0.854235 32.4557 0.166309 43.6695C1.18685 54.8832 6.26223 65.3674 12.2808 74.8894C29.2856 101.693 53.9608 122.761 83.0985 135.354C112.291 147.817 144.557 151.211 175.704 145.096C197.315 140.801 218.33 131.665 234.223 116.443C247.983 103.351 256.803 85.9093 259.19 67.0659C261.914 46.6757 250.907 28.6177 234.826 15.9536Z" fill="#D17EDF"/>
<path id="Calmness" d="M129.446 138.16C154.632 137.753 180.365 128.612 197.278 109.95C214.191 91.2872 220.532 62.4512 209.511 39.8275C200.206 20.7761 180.362 8.55558 159.761 3.5461C139.159 -1.46338 117.593 -0.193713 96.4556 1.49481C74.079 3.29423 50.984 5.75652 31.4555 16.8223C11.927 27.888 -3.20576 49.8288 0.585048 71.9587C8.72405 119.186 89.6265 138.811 129.446 138.16Z" fill="#AAD3F0"/>
<path id="Interest" d="M133.323 138.071C158.445 136.286 183.649 125.755 199.51 106.196C215.371 86.6378 220.189 57.5467 207.889 35.5098C197.573 16.9767 177.078 5.86693 156.222 2.00141C135.367 -1.86412 113.921 0.571533 92.9082 3.41434C70.6383 6.42338 47.7049 10.1975 28.8465 22.2732C9.98813 34.349 -3.98179 57.098 1.02613 78.9971C11.7265 125.745 93.6002 140.9 133.323 138.071Z" fill="#D1E9F1"/>
<path id="Distress" d="M182.143 131.071C205.608 118.508 226.015 97.7815 232.077 71.8624C238.139 45.9434 226.504 15.2566 202.052 4.77845C184.257 -2.85559 163.811 0.907961 145.074 5.78516C126.337 10.6624 106.968 16.6025 87.9653 12.9104C70.8407 9.57634 54.96 -1.45093 37.6063 0.159653C20.2526 1.77024 6.00013 16.6736 1.71084 33.5262C-2.57846 50.3789 1.60138 68.5322 9.75358 83.9861C27.2597 117.254 53.2077 137.361 90.057 144.398C121.03 150.377 154.198 146.037 182.143 131.071Z" fill="#F7E99D"/>
<path id="Craving" d="M205.975 27.0476C178.969 5.6016 142.866 -3.55516 108.685 1.24818C74.5043 6.05152 42.7066 24.5607 20.6924 51.1076C10.2416 63.6958 1.72332 78.61 0.209639 94.891C-1.30404 111.172 5.40434 128.908 19.5239 137.176C32.2223 144.614 48.3463 143.319 62.2248 138.456C76.1033 133.593 88.6638 125.511 102.124 119.612C115.584 113.714 130.894 109.892 144.973 114.145C162.428 119.414 174.427 135.815 191.312 142.688C210.949 150.731 238.499 141.353 248.214 124.281C253.567 114.879 252.493 104.626 251.807 98.0674C251.439 94.5342 249.391 83.6241 235.074 60.9565C229.076 51.4865 219.892 38.1073 205.975 27.0476Z" fill="#545C1E"/>
<path id="Tiredness" d="M54.4923 3.42724C33.807 6.63251 8.54965 16.8091 1.55105 41.3882C-3.80708 60.2111 5.4066 78.8134 18.6104 86.8364C31.8143 94.8595 48.1104 94.6714 63.8075 94.406L150.438 92.9244C162.182 92.7214 174.286 92.4317 185.761 86.7244C197.236 81.0172 208.053 68.4571 209.598 54.1135C216.589 -10.9564 84.7587 -1.25989 54.4923 3.42724Z" fill="#125475"/>
<path id="Boredom" d="M6.2989 87.3454C-1.00791 104.832 -3.55112 125.785 7.45638 141.728C18.4639 157.672 37.0406 167.141 56.0201 170.938C74.9996 174.736 94.6228 173.454 113.906 171.678C133.189 169.903 152.601 167.644 171.878 169.556C187.385 171.085 202.942 175.304 218.325 172.822C239.538 169.385 257.485 152.783 264.803 132.58C272.122 112.378 269.494 89.2991 260.006 70.0143C250.518 50.7295 234.624 35.0642 216.488 23.5291C182.741 2.06486 140.21 -5.52203 101.371 4.13017C62.5324 13.7824 23.8637 51.4101 6.2989 87.3454Z" fill="#6455BB"/>
<path id="Disappointment" d="M48.9602 11.3076C50.8877 14.2268 52.2441 17.5537 54.6333 20.0969C59.6162 25.3723 67.9866 25.9469 74.9519 23.9392C81.9172 21.9315 88.0503 17.7956 94.618 14.7042C101.186 11.6128 108.777 9.57422 115.635 11.9473C126.716 15.8277 131.912 29.8731 143.154 33.2685C153.289 36.3317 163.664 29.4059 171.047 21.8284C178.431 14.251 185.379 5.19799 195.553 2.29288C203.376 0.0236161 211.974 2.00162 218.838 6.33566C225.702 10.6697 230.94 17.1981 234.935 24.2544C239.935 33.2187 243.098 43.0903 244.239 53.2915C245.379 63.4928 244.473 73.819 241.575 83.6662C238.573 93.4823 233.644 102.601 227.076 110.49C220.508 118.379 212.434 124.88 203.324 129.611C185.767 138.577 165.493 140.467 145.817 139.905C110.296 138.894 74.118 129.98 45.5845 108.804C26.1499 94.3793 10.8224 73.8502 3.7124 50.6277C-1.99982 32.0077 -3.49156 12.247 16.9403 2.35873C28.0012 -2.99183 42.1999 1.08497 48.9602 11.3076Z" fill="#36A2BA"/>
<path id="Surprise" d="M202.307 1.70376C189.243 -1.52197 175.472 0.330546 162.316 3.14261C149.161 5.95467 136.09 9.70333 122.64 10.2628C104.155 11.0337 86.031 5.69706 67.6207 3.53661C49.2103 1.37615 28.9106 2.89677 14.9794 15.0884C-2.6335 30.4994 -3.92297 58.966 6.97847 79.6865C17.8799 100.407 38.2262 114.487 59.2501 124.784C84.5339 137.175 112.386 145.324 140.542 144.296C168.698 143.267 197.11 132.199 215.733 111.139C240.988 82.5432 249.874 13.4635 202.307 1.70376Z" fill="#C2C450"/>
<path id="Sympathy" d="M173.52 3.18118C160.045 0.228395 146.108 4.27484 133.027 8.66134C119.946 13.0478 106.459 17.8823 92.7307 16.5214C70.2707 14.3141 49.8606 -4.2197 27.9306 0.885839C12.7945 4.41368 1.97531 19.2889 0.28139 34.7318C-1.41253 50.1746 4.71282 65.6809 14.0396 78.1034C23.3664 90.526 35.7002 100.341 48.0252 109.752C64.094 122.024 81.7434 134.402 101.947 135.369C116.374 136.025 130.639 130.668 142.722 122.729C154.805 114.79 165.074 104.371 175.104 93.9363C182.907 85.7711 190.8 77.4347 195.929 67.3708C206.905 45.8446 200.439 9.11311 173.52 3.18118Z" fill="#818CEF"/>
<path id="Awe" d="M4.28527 108.579C17.6232 146.245 57.2882 158.041 66.0333 160.637C75.9508 163.591 108.503 173.315 140.434 154.191C151.47 147.473 160.864 138.372 167.927 127.554C174.99 116.735 179.544 104.474 181.256 91.6674C183.857 71.0773 178.539 49.451 166.091 32.8504C160.442 25.4934 153.58 19.1542 145.798 14.1056C120.968 -2.16282 94.8722 -0.422644 85.8836 0.644948C65.0304 3.1195 50.7737 12.1477 41.2941 18.297C28.7157 26.4547 14.6476 35.587 6.30059 53.6447C-5.6507 79.4377 2.79835 104.523 4.28527 108.579Z" fill="#7EB1E1"/>
<path id="Realization" d="M58.7042 119.841C65.253 123.681 114.942 151.793 169.458 131.959C188.882 124.808 206.129 112.757 219.525 96.9767C230.277 84.1746 241.928 69.0421 241.518 47.6637C241.153 29.1567 230.172 10.5379 212.955 3.73411C194.496 -3.52152 173.737 3.35164 155.49 11.1055C137.244 18.8594 117.888 27.682 98.3705 24.1386C85.9871 21.8965 75.1071 14.8641 63.999 8.95326C48.2967 0.595742 30.0906 -5.15596 14.7884 6.88128C1.16203 17.6631 -2.07568 29.3238 1.15537 45.8329C3.7342 58.8424 18.2771 96.0919 58.7042 119.841Z" fill="#207EB3"/>
<path id="Horror" d="M117.515 21.9274C129.776 18.5844 140.28 10.6821 152.019 5.82124C170.228 -1.73576 191.686 -2.30189 209.428 6.29212C227.17 14.8861 240.369 33.1979 240.863 52.9167C241.172 65.8183 236.271 78.4319 229.184 89.2175C210.777 117.217 177.675 133.726 144.266 136.155C110.857 138.584 77.3619 128.065 48.5598 110.944C35.7671 103.348 23.5925 94.2959 14.4396 82.5618C5.28671 70.8276 -0.674492 56.1491 0.0612104 41.2885C0.796913 26.4279 8.95513 11.5809 22.2264 4.89837C33.218 -0.68853 46.4711 -0.345534 58.2823 3.16684C68.2977 6.15292 77.5117 11.2128 86.4898 16.5971C92.5898 20.2942 99.4826 22.4852 106.598 22.9886C110.27 23.2238 113.956 22.8655 117.515 21.9274Z" fill="#763082"/>
<path id="Disgust" d="M145.482 133.307C167.123 130.026 188.299 121.075 203.711 105.554C219.124 90.0332 228.043 67.4948 224.58 45.8748C221.116 24.2549 203.914 4.8025 182.332 1.25966C168.049 -1.08761 153.626 3.2256 139.214 4.51054C119.682 6.27063 100.123 2.35335 80.5668 0.678396C61.0105 -0.99656 40.2513 -0.151477 23.423 9.95419C-44.1984 50.5992 52.0485 119.712 92.653 130.309C109.877 134.879 127.851 135.899 145.482 133.307Z" fill="#DFE2CD"/>
<path id="Excitement" d="M7.33922 89.1683C14.3939 104.803 26.071 118.032 39.4934 128.714C70.9925 153.814 114.096 165.276 153.001 154.872C191.906 144.467 224.665 110.687 229.882 70.732C232.137 53.3046 229.064 34.7373 218.67 20.4992C208.275 6.26105 189.904 -2.43225 172.686 1.11966C155.807 4.63877 142.601 18.7869 125.644 21.8286C96.5898 27.0481 70.5459 -1.73016 41.0833 0.0823033C-3.10521 2.81081 -6.75904 57.9278 7.33922 89.1683Z" fill="#F49B28"/>
<path id="Contempt" d="M2.47844 23.5564C7.83239 10.2598 22.384 2.45132 36.6218 1.05136C50.8596 -0.348592 65.1037 3.60595 78.7804 7.77919C92.457 11.9524 106.415 16.4905 120.753 16.3318C148.028 16.0287 173.281 -1.12826 200.528 0.0588699C211.176 0.647591 221.488 3.99559 230.451 9.77407C239.415 15.5525 246.721 23.5628 251.653 33.0183C256.35 42.5953 258.473 53.2297 257.815 63.8761C257.158 74.5224 253.741 84.8143 247.901 93.7401C236.511 110.691 217.238 120.773 197.691 126.678C165.82 136.222 131.877 136.4 99.9074 127.193C67.9642 117.857 39.3784 99.534 17.556 74.4079C7.8876 63.2137 -5.57597 45.696 2.44604 23.5079" fill="#768830"/>
<path id="Contemplation" d="M0.240549 80.9515C-2.39166 113.396 16.8523 145.435 44.5249 162.49C72.1976 179.546 107.087 182.716 138.881 175.69C161.112 170.785 182.618 160.902 198.975 145.049C215.332 129.196 226.128 107.048 225.844 84.2747C225.37 47.4378 195.299 15.2605 159.984 4.76371C105.878 -11.3245 5.74241 13.1573 0.240549 80.9515Z" fill="#B1B4FF"/>
<path id="Confusion" d="M15.0303 8.48861C27.0963 -1.48731 45.561 -2.82246 58.9235 5.29098C73.4542 14.1111 82.0921 32.5155 98.6724 36.226C112.394 39.292 125.899 30.8569 136.842 21.9897C147.785 13.1226 158.968 2.88069 172.969 1.51699C191.935 -0.318466 209.294 16.3153 212.836 35.0494C216.378 53.7835 208.735 73.1353 197.224 88.3619C186.907 101.79 173.723 112.746 158.633 120.431C143.543 128.116 126.929 132.335 110.001 132.781C93.0672 132.977 76.3036 129.39 60.9328 122.283C45.5621 115.175 31.9718 104.726 21.1536 91.6975C2.90179 69.3907 -12.5727 31.2934 15.0303 8.48861Z" fill="#C76D29"/>
<path id="Pain" d="M102.046 1.28133C135.201 -3.235 168.878 4.27367 196.975 22.4469C208.047 29.6569 218.332 38.778 224.321 50.5687C230.311 62.3594 231.428 77.1827 224.781 88.6205C218.133 100.058 204.95 106.342 191.86 107.46C178.769 108.577 165.704 105.11 153.325 100.682C140.946 96.2539 128.899 90.8051 116.104 87.6625C92.2672 81.9054 67.1791 84.46 44.9918 94.9036C36.6236 98.871 27.9657 104.092 18.8081 102.72C7.05752 100.962 -0.681528 88.0966 0.0474245 76.241C1.34227 55.2367 22.9314 38.9006 38.5112 27.3841C57.1949 13.5453 79.0294 4.57468 102.046 1.28133Z" fill="#FFAFC5"/>
<path id="Relief" d="M80.4266 0.140103C65.0605 0.344986 49.3853 0.803934 34.901 5.87636C20.4166 10.9488 6.96215 21.3366 2.03266 35.8931C-3.27121 51.5626 2.30095 69.4421 12.9921 82.075C23.6832 94.708 38.8205 102.785 54.1981 108.779C86.6537 121.339 121.676 125.822 156.249 121.842C177.549 119.379 199.316 113.231 215.219 98.8393C231.122 84.4476 239.52 60.269 230.712 40.7152C226.044 30.3129 217.145 22.3167 207.273 16.6665C189.475 6.49988 172.32 1.42043 152.096 0.780141C128.211 0.0396539 104.321 -0.173707 80.4266 0.140103Z" fill="#FF9782"/>
<path id="Sadness" d="M35.7871 19.3296C23.3184 26.6327 11.6854 36.2325 5.06411 49.1299C-1.55722 62.0273 -2.17249 78.5347 6.13548 90.3533C15.9013 104.244 34.9283 108.261 51.873 107.109C68.8178 105.958 85.4219 100.827 102.403 101.076C120.372 101.345 137.7 107.66 155.512 109.968C173.325 112.275 193.637 109.664 205.216 95.9174C217.084 81.8293 215.655 59.9635 206.196 44.1549C173.754 -10.1652 84.5323 -9.24435 35.7871 19.3296Z" fill="#54A0E5"/>
<path id="Adoration" d="M27.9292 132.915C41.4391 135.742 55.3357 131.558 68.3728 127.042C81.4099 122.527 94.8411 117.552 108.589 118.784C131.012 120.795 151.648 139.1 173.526 133.778C188.627 130.101 199.292 115.113 200.84 99.6611C202.388 84.2097 196.103 68.7574 186.654 56.4275C177.205 44.0977 164.775 34.4049 152.351 25.1091C136.162 12.9955 118.398 0.80043 98.1857 0.0331636C83.7526 -0.480256 69.5414 5.01742 57.5309 13.0679C45.5204 21.1185 35.3675 31.653 25.4414 42.1861C17.7191 50.428 9.90256 58.8345 4.87254 68.9486C-5.89049 90.5962 0.91189 127.218 27.9292 132.915Z" fill="#FFCCDA"/>
<path id="Fear" d="M44.4699 115.993C59.2077 115.536 73.2973 110.28 87.2144 105.419C101.131 100.558 115.594 96.0149 130.302 97.047C147.174 98.2205 162.57 106.599 178.641 111.871C194.711 117.143 213.995 118.782 227.08 108.058C239.319 98.042 242.137 79.553 236.932 64.6168C231.728 49.6806 219.977 37.9027 207.133 28.6768C181.995 10.7187 152.036 0.736514 121.151 0.0272644C90.2539 -0.554082 59.8968 8.17323 34.0261 25.0748C21.4726 33.3259 9.80773 43.8854 3.86872 57.6828C-9.18987 88.126 12.4271 116.986 44.4699 115.993Z" fill="#D2D0FF"/>
<path id="Anger" d="M89.3309 159.843C100.113 156.785 111.026 153.543 122.232 153.49C145.729 153.365 168.374 167.257 191.573 163.518C208.555 160.785 223.215 148.329 230.365 132.694C237.515 117.059 237.591 98.6864 232.382 82.2982C227.174 65.9099 216.976 51.4019 204.666 39.3915C169.062 4.73598 114.183 -8.66586 66.6104 5.68082C48.1566 11.237 30.5686 20.9055 18.1392 35.6996C-0.226889 57.5342 -0.174249 83.137 0.0416041 91.4981C0.530619 107.68 5.44606 123.418 14.2535 137.002C18.6103 143.84 24.4658 152.8 35.8665 159.032C42.1296 162.609 49.1243 164.716 56.3215 165.191C67.5231 165.867 78.6207 162.876 89.3309 159.843Z" fill="#F55051"/>
<path id="Awkwardness" d="M182.834 29.55C170.5 18.1472 155.743 9.6866 139.671 4.80328C123.599 -0.0800414 106.629 -1.25916 90.0365 1.35443C73.4853 4.22582 57.7997 10.8091 44.1569 20.61C30.514 30.411 19.2685 43.175 11.2644 57.9439C-0.965488 80.9097 -4.37944 109.533 6.75719 133.023C17.8938 156.514 45.3724 172.595 70.5985 166.387C82.436 163.486 93.18 156.21 105.353 155.438C122.912 154.351 138.274 166.932 155.486 170.577C162.705 172.045 170.156 171.929 177.325 170.236C184.494 168.543 191.21 165.313 197.009 160.771C237.196 128.964 213.92 58.8105 182.834 29.55Z" fill="#D8E0A8"/>
<path id="Amusement" d="M204.652 139.151C200.044 141.731 194.963 143.355 189.713 143.927C184.462 144.499 179.151 144.006 174.095 142.479C152.696 135.884 138.788 111.692 116.518 109.627C102.375 108.312 89.2716 116.524 77.6016 124.629C65.9316 132.734 53.3752 141.43 39.1633 141.269C22.3695 141.082 7.61513 127.6 2.49946 111.592C-2.61622 95.583 0.629642 77.9005 7.62953 62.6181C21.7573 31.7326 51.5234 8.67437 84.8223 2.04554C133.957 -7.72934 171.063 20.334 180.026 27.1705C194.626 38.213 206.402 52.5584 214.388 69.0306C220.235 81.3186 228.592 98.8325 222.08 117.316C220.984 120.492 216.604 132.384 204.652 139.151Z" fill="#FFC559"/>
<path id="Desire" d="M228.305 26.7218C196.865 3.9012 155.744 -4.67718 117.573 2.43495C79.4028 9.54708 44.4312 31.8834 20.9782 62.8242C9.83961 77.4994 0.989431 94.6924 0.0682274 113.089C-0.852976 131.486 7.579 151.106 23.868 159.728C38.5111 167.481 56.6241 165.247 72.0204 159.11C87.4167 152.974 101.118 143.3 115.997 135.96C130.877 128.62 147.899 123.607 163.958 127.711C183.852 132.799 198.155 150.665 217.483 157.583C239.954 165.584 270.513 153.779 280.617 134.093C286.177 123.251 284.476 111.766 283.383 104.421C282.793 100.454 279.964 88.2829 262.772 63.5381C255.487 53.0962 244.526 38.5167 228.305 26.7218Z" fill="#AC4ABD"/>
</svg>
`;

export const SOUND_SAMPLES = {
    'adoration': [
        'Adoration2.mp3',
        'Adoration4.mp3',
        'Adoration5.mp3'
    ],
    'amusement': ['Amusement7.mp3',
        'Amusement18.mp3'],
    'anger': ['Anger1.mp3',
        'Anger2.mp3',
        'Anger5.mp3'],
    'awe': ['Awe1.mp3',
        'Awe8.mp3'],
    'awkwardness': ['Awkwardness6.mp3',
        'Awkwardness8.mp3'],
    'boredom': ['Boredom1.mp3',
        'Boredom8.mp3'],
    'calmness': ['Calmness2.mp3',
        'Calmness4.mp3',
        'Calmness7.mp3'],
    'confusion': ['Confusion1.mp3',
        'Confusion3.mp3',
        'Confusion5.mp3'],
    'contemplation': ['Contemplation1.mp3',
        'Contemplation6.mp3',
        'Contemplation7.mp3'],
    'contempt': ['Contempt1.mp3',
        'Contempt7.mp3',
        'Contempt8.mp3'],
    'craving': ['Craving1.mp3',
        'Craving5.mp3',
        'Craving7.mp3'],
    'desire': ['desire1.mp3',
        'desire2.mp3',
        'desire3.mp3'],
    'disappointment': ['Disappointment1.mp3',
        'Disappointment7.mp3',
        'Disappointment8.mp3'],
    'disgust': ['Disgust1.mp3',
        'Disgust4.mp3',
        'Disgust7.mp3'],
    'distress': ['Distress3.mp3',
        'Distress4.mp3',
        'Distress7.mp3'],
    'excitement': ['Excitement2.mp3',
        'Excitement5.mp3',
        'Excitement27.mp3'],
    'fear': ['Fear16.mp3',
        'Fear28.mp3',
        'Fear66.mp3',
        'Fear71.mp3'],
    'horror': ['Horror2.mp3',
        'Horror8.mp3'],
    'interest': ['Interest2.mp3',
        'Interest12.mp3',
        'Interest15.mp3'],
    'joy': ['Joy1.mp3',
        'Joy4.mp3',
        'Joy8.mp3'],
    'pain': ['Pain1.mp3',
        'Pain2.mp3',
        'Pain3.mp3'],
    'realization': ['Realization1.mp3',
        'Realization2.mp3',
        'Realization6.mp3'],
    'relief': ['Relief2.mp3',
        'Relief6.mp3'],
    'sadness': ['Sadness1.mp3',
        'Sadness2.mp3',
        'Sadness3.mp3'],
    'satisfaction': ['Satisfaction3.mp3',
        'Satisfaction5.mp3',
        'Satisfaction7.mp3'],
    'surprise': ['Surprise1.mp3',
        'Surprise4.mp3',
        'Surprise8.mp3'],
    'sympathy': ['Sympathy4.mp3',
        'Sympathy5.mp3',
        'Sympathy7.mp3'],
    'tiredness': ['Tiredness4.mp3',
        'Tiredness5.mp3',
        'Tiredness6.mp3'],
    'triumph': ['Triumph6.mp3',
        'Triumph11.mp3',
        'Triumph17.mp3']
};