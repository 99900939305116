import navaid from "navaid";
import {Menu} from "./Menu";
import {Signal} from "signal-ts";
import {gsap} from 'gsap';
import {StateManager} from "./StateManager";
import {Globals} from "../utils/Globals";
export class Router {

    private _outer:HTMLElement = document.querySelector('.PagesWrapper');

    private router = navaid('/');
    private showingSubPages = false;
    private menu: Menu;
    private currentSubPageElement: HTMLDivElement = null;
    public activeID = null;
    public firstLoad = true;
    public signalRouteChange: Signal<string> = new Signal();
    private transitionCoverLayer = document.querySelector('.TransitionCoverLayer');

    constructor(menu: Menu) {
        this.menu = menu;
        this.router
            .on('/', () => {
                Globals.BLOB_AUDIO_PLAYER.stopSound();
                this._outer.style.height = 'auto';
                this.showHomePage();


            })
            .on('/emotions', params => {
                Globals.BLOB_AUDIO_PLAYER.stopSound();
                this.showSubpage('emotions');
            })
            .on('/about', params => {
                Globals.BLOB_AUDIO_PLAYER.stopSound();
                this.showSubpage('about');
            })
            .on('/my-songs', params => {
                Globals.BLOB_AUDIO_PLAYER.stopSound();
                this.showSubpage('my-songs');
            });
        this.router.listen();
    }

    private showCoverLayer = (callback: () => void) => {
        if (this.firstLoad) {
            callback();
            this.firstLoad = false;
        } else {
            gsap.to(this.transitionCoverLayer, {opacity: 1, duration: 0.5, onComplete: callback});
        }
    }

    private showHomePage = () => {
        this.menu.routeChange('/');
        const prevId = this.activeID;
        this.activeID = '/';
        this.showCoverLayer(() => {
            if (Globals.STATE_MANAGER) {
                Globals.STATE_MANAGER.gotoStep(0);
            }
            if (this.showingSubPages) {
                document.documentElement.classList.remove('show-pages');
                document.documentElement.classList.remove('page-' + prevId);
                document.documentElement.classList.add('page-' + this.activeID);
                if (this.currentSubPageElement) {
                    this.currentSubPageElement.classList.remove('page-visible');
                    this.currentSubPageElement = null;
                }
                this.showingSubPages = false;
            }
            this.signalRouteChange.emit(this.activeID);
            if (!this.firstLoad) {
                gsap.to(this.transitionCoverLayer, {opacity: 0, duration: 0.75, ease: 'sine.inOut'});
                //Globals.STATE_MANAGER.gotoStep(0);
            }
        });
    }

    private showSubpage = (id: string) => {
        if (this.activeID === id) {
            return;
        }

        this.menu.routeChange('/' + id);
        const prevId = this.activeID;
        this.activeID = id;
        this.showCoverLayer(() => {
            if (Globals.STATE_MANAGER) {
                Globals.STATE_MANAGER.gotoStep(0);
            }
            if (!this.showingSubPages) {
                document.documentElement.classList.add('show-pages');
                this.showingSubPages = true;
            }
            document.documentElement.classList.remove('page-' + prevId);
            document.documentElement.classList.add('page-' + this.activeID);
            if (this.currentSubPageElement) {
                this.currentSubPageElement.classList.remove('page-visible');
            }
            this._outer.style.height = 'auto';
            window.scrollTo(0,0);

            this.currentSubPageElement = document.getElementById(id) as HTMLDivElement;
            this.currentSubPageElement.classList.add('page-visible');
            this.signalRouteChange.emit(this.activeID);
            if (!this.firstLoad) {
                gsap.to(this.transitionCoverLayer, {opacity: 0, duration: 0.75, ease: 'sine.inOut'});
            }
        });
    }

}
