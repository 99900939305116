export class InfoDialogs {
    private infoDialogs = Array.from(document.querySelectorAll('.InfoDialog-dialog'));
    private infoDialogButton = document.querySelector('.InfoDialog-button');
    private currentIndex: number = 0;

    constructor() {
        this.infoDialogButton.addEventListener('click', this.buttonClick);
        this.infoDialogs.forEach((dialog => dialog.addEventListener('click', this.dialogClick)));

    }

    dialogClick = (event: MouseEvent) => {
        if (event) {
            event.preventDefault();
        }
        this.infoDialogButton.classList.add('visible');
        this.infoDialogs.forEach((dialog) => dialog.classList.remove('visible'));
        return false;
    }

    public setStep = (index: number) => {
        if (this.currentIndex !== index) {
            this.infoDialogs[this.currentIndex].classList.remove('visible');
            this.currentIndex = index;
        }
    }

    public show() {
        this.infoDialogButton.classList.add('visible');
    }

    private buttonClick = (event: MouseEvent) => {
        if (event) {
            event.preventDefault();
        }

        this.infoDialogs[this.currentIndex].classList.add('visible');
        this.infoDialogButton.classList.remove('visible');
        return false;
    }

    hide() {
        this.infoDialogs.forEach((dialog) => dialog.classList.remove('visible'));
        this.infoDialogButton.classList.remove('visible');
    }
}
