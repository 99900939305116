import {Container, Texture} from "pixi.js";
import {Blob} from "./Blob";
import {gsap} from "gsap";
import {IAPIResponse} from "./RecordingAPI";
import {Globals} from "../utils/Globals";
import {MySongsResultBlobs} from "./MySongsResultBlobs";

export class MySongsView {
    private _outer:HTMLElement = document.querySelector('.PagesWrapper');

    private container = document.querySelector('#my-songs');
    private listContainer = this.container.querySelector('ul');
    private messageBrowse: HTMLElement = document.querySelector('#my-songs').querySelector('.Message-browse');
    private messageEmpty: HTMLElement = this.container.querySelector('.Message-empty');
    public stage = new Container();
    private currentResults: IAPIResponse[];
    private currentlyBuildToIndex = -1;
    private currentListClass: string;
    private currPlayButton: Element;
    audioElement: HTMLAudioElement = document.createElement('audio');
    private listItems: HTMLElement[] = [];
    private listItemBlobResults: MySongsResultBlobs[] = [];
    private texture: any;
    private blobs: Blob[];

    constructor(texture: any, blobs: Blob[]) {

        this._outer.style.height = 'auto';
        this.texture = texture;
        this.blobs = blobs;
        this.getInitialResults();
        Globals.SIGNAL_SAVED_SONG_RESULTS_CHANGES.add(() => {
            this.currentResults = Globals.SAVED_SONG_RESULTS;
            console.log(this.currentResults);
            this.buildResults();
        });
        this.buildResults();
        window.addEventListener('scroll', this.onScroll);


        this.audioElement.addEventListener('playing', () => {
            this.currPlayButton.classList.add('playing')
        })
        this.audioElement.addEventListener('pause', () => {
            this.currPlayButton.classList.remove('playing');
        })
        Globals.STATE_MANAGER.router.signalRouteChange.add((activeID:string) => {
            if (activeID !== 'my-songs') {
                if (this.audioElement.src && this.audioElement.src !== '' && !this.audioElement.paused) {
                    this.audioElement.pause();
                }
                console.log('mysong - removing');
                var length =  this.listItemBlobResults.length;
                for (var i = 0; i < length; i++) {
                    var getView = this.listItemBlobResults[i];
                    this.stage.removeChild(getView.pixiContainer);
                    var getListItem = this.listItems[i];
                    this.listContainer.removeChild(getListItem);
                }
                this.listItemBlobResults = [];
                this.listItems = [];
            }
            else {
                this.currentlyBuildToIndex = 0;
                this.buildResults();
                this.resize();
            }
        });
    }

    private getInitialResults() {
        var prevResults = localStorage.getItem('SavedResults');
        if (prevResults) {
            prevResults = JSON.parse(prevResults);
        }
        Globals.SAVED_SONG_RESULTS = Array.isArray(prevResults) ? prevResults : [];
        this.currentResults = Globals.SAVED_SONG_RESULTS;
    }

    private buildResults() {
        this.toggleCopy();

        this.currentResults.slice().reverse().forEach((result, index) => {
            if (this.currentlyBuildToIndex < index) {
                this.currentlyBuildToIndex++;
                var item = document.createElement('li');
                item.innerHTML = `<div class="SongUI-albumArt visible">
            <img src="${result.spotifyTrackInfo.album.images[0].url}"/>
            <div class="PlayButton">
                <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23.4995 13.2451C24.1662 13.63 24.1662 14.5922 23.4995 14.9771L2.33284 27.1977C1.66618 27.5826 0.832844 27.1015 0.832844 26.3317L0.832846 1.89053C0.832846 1.12073 1.66618 0.639604 2.33285 1.0245L23.4995 13.2451Z"
                        fill="#1C1C1C"/>
                </svg>
                <svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="13" width="5" height="26" rx="2.5" fill="#1C1C1C"/>
                    <rect width="5" height="26" rx="2.5" fill="#1C1C1C"/>
                </svg>
                <button>Play / Pause</button>
            </div>
        </div>
        <div class="mySongs-info">
            <h5>${result.spotifyTrackInfo.artists.map((artist) => artist.name).join(', ')}</h5>
            <h3>${result.spotifyTrackInfo.name}</h3>
        </div>`;
                const playButton = item.querySelector('.PlayButton');
                const img = item.querySelector('img');
                img.onload = () => {
                    img.classList.add('loaded');
                }
                let src = null;
                if (result.spotifyTrackInfo.preview_url) {
                    src = result.spotifyTrackInfo.preview_url;
                } else {
                    item.classList.add('no-preview');
                }
                if (src) {
                    item.addEventListener('click', (event: MouseEvent) => {
                        if (this.audioElement.src !== src) {
                            this.audioElement.src = src;
                        }
                        if (this.currPlayButton) {
                            this.currPlayButton.classList.remove('playing');
                        }
                        this.currPlayButton = playButton;
                        this.togglePlayPause(event);
                    });
                }
                if (this.currentListClass) {
                    this.listContainer.classList.remove(this.currentListClass);
                }
                this.currentListClass = 'list-length-' + Math.min(this.currentResults.length, 3);
                this.listContainer.classList.add(this.currentListClass);
                this.listContainer.prepend(item);
                this.listItems.push(item);
                const resultView = new MySongsResultBlobs(result, item, this.texture, this.blobs, this.stage);
                this.listItemBlobResults.push(resultView);
                this.stage.addChild(resultView.pixiContainer);
            }
        });
    }

    private toggleCopy = () => {
        if (this.currentResults.length) {
            this.messageBrowse.style.display = 'inline-block';
            this.messageEmpty.style.display = 'none';
        } else {
            this.messageBrowse.style.display = 'none';
            this.messageEmpty.style.display = 'inline-block';
        }
    }

    private togglePlayPause = (event: MouseEvent) => {
        if (event) {
            event.preventDefault();
        }
        this.audioElement.paused ? this.audioElement.play() : this.audioElement.pause();
        return false;
    }

    public resize = () => {
        this.onScroll();
        this.listItemBlobResults.forEach((albumView) => albumView.resize());
    }

    private onScroll = () => {
        this.stage.y = -window.scrollY;
    }

}