import gsap from 'gsap';

import Stats from 'stats.js';
import {Blob} from "./Blob";
import MotionPathPlugin from 'gsap/MotionPathPlugin';
// import MotionPathHelper from 'gsap/MotionPathHelper';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import {Globals} from "../utils/Globals";
import {Application, Container, DEG_TO_RAD, Renderer, Texture, utils} from "pixi.js";
import {WRAP_MODES} from '@pixi/constants';
import {BlobBatchRendererPluginFactory} from "./BlobBatchRenderer";
// import * as EssentialsPlugin from '@tweakpane/plugin-essentials';
import {pointsInPath} from "../utils/Helpers";
import {RecordingUI} from "./RecordingUI";
import {RecordingAPI} from "./RecordingAPI";
import {BLOBS_SVG} from "./BlobData";
import {StateManager} from "./StateManager";
import {EmotionsView} from "./EmotionsView";
import {Router} from "./Router";
import {MySongsView} from "./MySongsView";
import {parseNumber} from "@tweakpane/core";

const seedrandom = require('seedrandom');

Renderer.registerPlugin('blob', BlobBatchRendererPluginFactory.create({}));
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);
// gsap.registerPlugin(MotionPathHelper);
// MorphSVGPlugin.defaultUpdateTarget = false;
const noiseTexture = new URL(
    '../assets/images/noise_reduced_4@2x.png',
    import.meta.url
);
var stats = new Stats();
stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
stats.dom.classList.add('statsjs')
//document.body.appendChild(stats.dom);

const initOutlined = [false,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    true,
    true,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    true,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    true,
    true,
    false,
    true
]

export class CanvasRenderer {
    private DEBUG_PATH = false;
    private ANIMATE_PATHS = false;
    private PARAMS = {
        noiseStep: this.ANIMATE_PATHS ? 0.004 : 0, maxOffset: this.ANIMATE_PATHS ? 10 : 0, pointMorphSkips: 2, tension: 1,
        showMorphingSVGPath: true, //TODO:, This has huge performance implications in firefox. but the last result morph doesn't work if not true currently.
        morphPath: false,
        showPath: false,
        alignBlobs: true,
        rotateBlobs: true,
        rotationSpeed: 150,/*
        scale: {min: 0.75, max: 1.25, interval: 0.1},
        offsetX: {min: -50, max: 50, interval: 10},
        offsetY: {min: -50, max: 50, interval: 10},*/
        scale: {min: 0.33, max: 1, interval: 0.1},
        offsetX: {min: 0, max: 0, interval: 1},
        // offsetY: {min: 0, max: 0, interval: 1},
        offsetY: {min: -168, max: 168, interval: 1},
    };
    private stage: Container;
    private app: Application;
    private ticker = 0;

    private texture = Texture.from(noiseTexture.pathname, {wrapMode: WRAP_MODES.MIRRORED_REPEAT, resourceOptions: {autoLoad: false}});
    public blobs: Blob[] = [];
    private morphTween: gsap.core.Timeline;
    private morphTweenReset: gsap.core.Timeline;
    private currRawPath: any;
    private currRawPath2: any;
    private timelineTweens: gsap.core.Tween[] = [];
    private revolutionDuration: number = 150;//130;
    private motionPathW: number;
    private motionPathH: number;
    private rotationTween: gsap.core.Tween;
    private rotationValue = 0;
    private randomScale = gsap.utils.pipe(gsap.utils.interpolate(this.PARAMS.scale.min, this.PARAMS.scale.max), gsap.utils.snap(this.PARAMS.scale.interval), gsap.utils.clamp(this.PARAMS.scale.min, this.PARAMS.scale.max));
    private offsetX = gsap.utils.pipe(
        gsap.utils.interpolate(this.PARAMS.offsetX.min, this.PARAMS.offsetX.max),
        gsap.utils.snap(this.PARAMS.offsetX.interval),
        gsap.utils.clamp(this.PARAMS.offsetX.min, this.PARAMS.offsetX.max));
    private offsetY = gsap.utils.pipe(gsap.utils.interpolate(this.PARAMS.offsetY.min, this.PARAMS.offsetY.max), gsap.utils.snap(this.PARAMS.offsetY.interval), gsap.utils.clamp(this.PARAMS.offsetY.min, this.PARAMS.offsetY.max));
    private speedUpPathValue = 0;
    private mouseSpeedUpValue = 0;
    private offsetXSeedValue = 16;
    private offsetYSeedValue = 5;
    private scaleSeedValue = 5;
    private outlinedSeedValue = 2;
    private landingPathSeedValue = 8; //8
    public recordingUI: RecordingUI;
    private recordingAPI: RecordingAPI;
    private morphTweenResult: gsap.core.Timeline;
    private blobsTimelines: gsap.core.Timeline[] = [];
    private blobsProgressTracker = 0;
    private emotions: EmotionsView;
    private mySongs: MySongsView;
    private blobPaths: SVGPathElement[];

    private activeStage: Container;
    private router: Router;
    private canvasContainer = document.querySelector('.CanvasRenderer') as HTMLDivElement;

    constructor(recordingAPI: RecordingAPI, router: Router, loadCallback: () => void) {
        this.texture.baseTexture.resource.load().then(() => {
            this.recordingUI.resize();
            loadCallback();
            console.log('loaded texture');
        });
        utils.skipHello();
        this.recordingAPI = recordingAPI;
        this.router = router;
        /*        if (Globals.DEBUG) {
                    Globals.DEBUG_PANE.registerPlugin(EssentialsPlugin);
                }*/

        const svgContainer = document.createElement('div');
        svgContainer.innerHTML = BLOBS_SVG;
        this.blobPaths = Array.from(svgContainer.querySelectorAll('path'));


        this.setupPixi();
        this.emotions = new EmotionsView(this.blobPaths, this.texture);

        this.setupMorphPaths();
        this.setupBlobs();
        this.mySongs = new MySongsView(this.texture, this.blobs);
        this.setActiveStage(this.router.activeID);
        this.recordingUI = new RecordingUI(this.texture, this.recordingAPI);
        this.stage.addChild(this.recordingUI.container);
        /*        if (Globals.DEBUG) {
                    this.setupTweakPane();
                }*/

        this.resize();
        window.addEventListener('resize', this.resize);
        gsap.ticker.add(this.animate);
        this.router.signalRouteChange.add(this.routeChange);

    }

    public routeChange = (id: string) => {
        this.setActiveStage(id);
    }
    public setActiveStage = (id: string) => {
        if (this.activeStage) {
            this.app.stage.removeChild(this.activeStage);
        }
        if (id === 'emotions') {
            this.app.resize();
            this.emotions.resize();
            this.activeStage = this.emotions.stage;
        } else if (id === 'about') {
            this.activeStage = null;
        } else if (id === 'my-songs') {
            this.app.resize();
            this.mySongs.resize();
            this.activeStage = this.mySongs.stage;
        } else {
            this.app.resize();
            this.activeStage = this.stage;
        }
        if (this.activeStage) {
            this.app.stage.addChild(this.activeStage);
        }
    }

    setupPixi() {
        const browserVersion = parseFloat(Globals.browser.getBrowserVersion());
        const safariDiscreteGPUBugBrowser = Globals.browser.getOSName(true) === 'macos' && browserVersion >= 14 && browserVersion < 16;
        const resolution = safariDiscreteGPUBugBrowser ? 1 : (Math.min(window.devicePixelRatio, 4) || 1);
        // Autodetect, create and append the renderer to the body element
        this.app = new Application({
            resizeTo: this.canvasContainer,
            antialias: true,
            powerPreference: 'high-performance',
            width: this.canvasContainer.clientWidth, height: this.canvasContainer.clientHeight, backgroundColor: 0xFEF7EF, resolution: resolution,
        });
        document.querySelector('.CanvasRenderer').appendChild(this.app.view);
        this.app.ticker.stop();
        this.stage = new Container();
    }

    setupBlobs() {

        let scales = [
            0.5,
            0.7,
            0.5,
            0.4,
            0.6,
            0.7,
            0.8,
            1.1,
            0.65,
            0.8,
            0.7,
            1.1,
            0.95,
            0.7,
            0.6,
            1,
            0.9,
            0.47,
            0.75,
            0.4,
            1,
            1,
            0.9,
            1,
            0.7,
            0.5,
            0.6,
            0.8,
            0.6,
            1
        ];
        let offsetY = [0,
            0,
            90,
            0,
            0,
            -120,
            0,
            -80,
            0,
            -168,
            0,
            -120,
            0,
            60,
            -100,
            -200,
            70,
            120,
            0,
            -80,
            -200,
            0,
            -100,
            -150,
            0,
            0,
            -50,
            -120,
            120,
        ];
        let offsetX = [0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            -60,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ];
        let outlined = initOutlined;
        let ambientMove = [0,
            0,
            0.75,
            0.5,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0.75,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0.75
        ]
        this.blobPaths.forEach((path, index) => {
            //const blob = new Blob(this.texture, path, this.randomScale(seedrandom(this.scaleSeedValue + index)()), this.offsetX(seedrandom(this.offsetXSeedValue + index)()), this.offsetY(seedrandom(this.offsetYSeedValue + index)()), seedrandom(this.outlinedSeedValue + index)() >= 0.5, true, true);
            const blob = new Blob(this.texture, path, scales[index] === 1 ? 1 : scales[index], offsetX.length > index ? offsetX[index] : 0, offsetY.length > index ? offsetY[index] : 0,
                outlined[index]/*seedrandom(this.outlinedSeedValue + index)() >= 0.5*/, true, true, index, ambientMove[index]);
            // console.log(blob.name, blob.el.width, blob.el.height);
            this.blobs.push(blob);
            this.stage.addChild(blob.container);
        });

        this.setupMorphTween();
        this.setupMorphTweenReset();
        this.setupBlobAnimations();

        this.setupBlobRotation();

    }

    setupMorphPaths() {
        let motionPath = document.getElementById('motionPath');
        this.motionPathW = motionPath.clientWidth;
        this.motionPathH = motionPath.clientHeight

        //Set viewbox to match viewport:
        const motionPathFillWidth = this.motionPathW - Globals.menuWidth;
        motionPath.setAttribute('viewBox', `0 0 ${this.motionPathW} ${this.motionPathH}`)

        //This is the path definition for the "Try your own recording" screen.
        let pathRecording = document.getElementById("pathRecording") as unknown as SVGRectElement;
        pathRecording.setAttribute('width', String(motionPathFillWidth * 0.9));
        pathRecording.setAttribute('height', String(this.motionPathH * 1.1));

        /*        pathRecording.setAttribute('rx', String(motionPathFillWidth * 0.045));
                pathRecording.setAttribute('ry', String(this.motionPathH * 0.55));        */

        pathRecording.setAttribute('rx', String(motionPathFillWidth * 0.44));
        pathRecording.setAttribute('ry', String(this.motionPathH * 0.54));

        pathRecording.setAttribute('y', String(-this.motionPathH * 0.15));
        pathRecording.setAttribute('x', String(motionPathFillWidth * 0.05 + Globals.menuWidth));

        //This is the path definition for the "Your result" screen:
        let pathResult = document.getElementById("pathResult") as unknown as SVGRectElement;
        let resultWidth = Math.max(Math.min(motionPathFillWidth * 0.6, 250 * Globals.blobScaleFactor), 200);
        let resultHeight = resultWidth;
        Globals.RESULT_DIAMETER = resultWidth;
        pathResult.setAttribute('width', String(resultWidth));
        pathResult.setAttribute('x', String(Globals.winW * 0.5 + (-resultWidth * 0.5 + Globals.menuWidth * 0.5)));
        pathResult.setAttribute('y', String(Globals.winH * 0.5 + (-resultHeight * 0.5)));
        pathResult.setAttribute('height', String(resultHeight));
        pathResult.setAttribute('rx', String(resultWidth * 0.49));
        pathResult.setAttribute('ry', String(resultHeight * 0.49));

        //This is the path definition for the landing scene, and also the only visible svg element:
        let pathLanding2 = document.getElementById("pathLanding2") as unknown as SVGRectElement;
        let smallerW = motionPathFillWidth * 1.8;
        let smallerH = this.motionPathH * 1.8;
        pathLanding2.setAttribute('width', String(smallerW));
        pathLanding2.setAttribute('x', String(-smallerW * 0.51 + Globals.menuWidth));
        pathLanding2.setAttribute('y', String(-smallerH * 0.55));
        pathLanding2.setAttribute('height', String(smallerH));
        pathLanding2.setAttribute('rx', String(smallerW * 0.49));
        pathLanding2.setAttribute('ry', String(smallerH * 0.49));

        //This is the path definition for the landing scene, and also the only visible svg element:
        let pathVisible = document.getElementById("pathVisible") as unknown as SVGRectElement;
        smallerW = motionPathFillWidth * 2;
        smallerH = this.motionPathH * 1.5;
        pathVisible.setAttribute('width', String(smallerW));
        pathVisible.setAttribute('x', String(-smallerW * 0.51 + Globals.menuWidth));
        pathVisible.setAttribute('y', String(-smallerH * 0.55));
        pathVisible.setAttribute('height', String(smallerH));
        pathVisible.setAttribute('rx', String(smallerW * 0.49));
        pathVisible.setAttribute('ry', String(smallerH * 0.49));

        //Soften up result path:
        /*        var pth = MorphSVGPlugin.convertToPath("#pathVisible")[0].getAttribute('d');
                let convertedAnchorsRect = pointsInPath(MotionPathPlugin.stringToRawPath(pth), 2);
                let rawPath = MotionPathPlugin.arrayToRawPath(convertedAnchorsRect, {curviness: 1, type: 'bezier'});
                console.log(pathResult.getAttribute('d'));
                pathResult.setAttribute("d", MotionPathPlugin.rawPathToString(rawPath));
                console.log(pathResult.getAttribute('d'));*/


        this.prevPaths.forEach((path) => path.parentNode.removeChild(path));
        this.prevPaths = [];


        this.insertParent(pathRecording, MorphSVGPlugin.convertToPath(pathRecording, false)[0]);
        this.insertParent(pathVisible, MorphSVGPlugin.convertToPath(pathVisible, false)[0]);
        this.insertParent(pathLanding2, MorphSVGPlugin.convertToPath(pathLanding2, false)[0]);
        this.insertParent(pathResult, MorphSVGPlugin.convertToPath(pathResult, false)[0]);
        // this.insertParent(pathResult, this.softenPath(pathResult));
        // this.currRawPath = null;
    }

    private prevPaths = [];
    private insertParent = (element, path) => {
        if (element.parentNode) {
            this.prevPaths.push(path);
            path.setAttribute('id', element.getAttribute('id') + 'PATH')
            element.parentNode.insertBefore(path, element);
        }
    }

    private softenPath = (path: SVGRectElement) => {
        var pth = MorphSVGPlugin.convertToPath(path, false)[0];
        let convertedAnchorsRect = pointsInPath(MotionPathPlugin.stringToRawPath(pth.getAttribute('d')), 32);
        let rawPath = MotionPathPlugin.arrayToRawPath(convertedAnchorsRect, {curviness: 1, type: 'bezier'});
        pth.setAttribute("d", MotionPathPlugin.rawPathToString(rawPath));
        return pth;
    }

    public currMorphTweens: gsap.core.Tween[] = [];
    public currMorphResultTweens: gsap.core.Tween[] = [];

    private morphSVGRenderCallback = (rawPath) => {
        MotionPathPlugin.cacheRawPathMeasurements(rawPath, 50);
        this.currRawPath = rawPath;
    }
    private morphSVGRender2Callback = (rawPath) => {
        MotionPathPlugin.cacheRawPathMeasurements(rawPath, 50);
        this.currRawPath2 = rawPath;
    }
    private setupMorphAnimations = () => {
        var tweenPositions = [];
        this.currMorphTweens.forEach(tween => {
            tweenPositions.push(tween.progress());
            this.morphTween.remove(tween);
            tween.kill();
        });
        this.currMorphTweens = [];

        var tweenPositionsResult = [];
        this.currMorphResultTweens.forEach(tween => {
            tweenPositionsResult.push(tween.progress());
            this.morphTweenResult.remove(tween);
            tween.kill();
        });
        this.currMorphResultTweens = [];

        let tween = gsap.to("#pathVisiblePATH", {
            duration: 3,
            immediateRender: false,
            ease: 'power1.inOut',
            morphSVG: {
                shape: "#pathRecordingPATH",
                type: "rotational",
                // shapeIndex: 7,
                origin: '50% 50%, 25% 25%',
                // map: "position",
                updateTarget: this.PARAMS.showMorphingSVGPath,
                //precompile: "log",
                render: this.morphSVGRenderCallback
            }
        });

        // tween.progress(0.999);

        this.currMorphTweens.push(tween);
        this.morphTween.add(tween, 0);
        let tween2 = gsap.to("#pathLanding2PATH", {
            duration: 3,
            immediateRender: false,
            ease: 'power1.inOut',
            morphSVG: {
                shape: "#pathRecordingPATH",
                type: "rotational",
                // shapeIndex: 7,
                origin: '50% 50%, 25% 25%',
                // map: "position",
                updateTarget: this.PARAMS.showMorphingSVGPath,
                //precompile: "log",
                render: this.morphSVGRender2Callback
            }
        });
        this.currMorphTweens.push(tween2);
        // tween2.progress(0.999);
        this.morphTween.add(tween2, 0);

        let tween3 = gsap.to("#pathVisiblePATH", {
            duration: 3 * this.toRecordingSpeedFactor,
            immediateRender: false,
            ease: 'power1.inOut',
            morphSVG: {
                shape: "#pathResultPATH",
                type: "rotational",
                // shapeIndex: 7,
                origin: '50% 50%, 50% 50%',
                // map: "position",
                updateTarget: this.PARAMS.showMorphingSVGPath,
                //precompile: "log",
                render: this.morphSVGRenderCallback
            }
        });
        this.currMorphResultTweens.push(tween3);
        this.morphTweenResult.add(tween3, 0);
        let tween4 = gsap.to("#pathLanding2PATH", {
            duration: 3 * this.toRecordingSpeedFactor,
            immediateRender: false,
            ease: 'power1.inOut',
            morphSVG: {
                shape: "#pathResultPATH",
                type: "rotational",
                // shapeIndex: 7,
                origin: '50% 50%, 50% 50%',
                // map: "position",
                updateTarget: this.PARAMS.showMorphingSVGPath,
                //precompile: "log",
                render: this.morphSVGRender2Callback
            }
        });
        this.currMorphResultTweens.push(tween4);
        this.morphTweenResult.add(tween4, 0);

        tweenPositions.forEach((val, index) => {
            //This works around a bug where progress == 1 won't trigger the morphSVG Render callback.
            this.currMorphTweens[index].progress(Math.max(val - 0.001, 0));
            this.currMorphTweens[index].progress(val);
        });
        if (this.morphTweenResult.progress() > 0) {
            tweenPositionsResult.forEach((val, index) => {
                //This works around a bug where progress == 1 won't trigger the morphSVG Render callback.
                this.currMorphResultTweens[index].progress(Math.max(val - 0.001, 0));
                this.currMorphResultTweens[index].progress(val);
            });
        }

    }

    setupMorphTweenReset = () => {
        this.morphTweenReset = gsap.timeline({paused: true});
        this.morphTweenReset.to(this, {speedUpPathValue: 1.5, ease: 'power4.inOut', duration: 4, immediateRender: false}, 0);
        this.morphTweenReset.to(this, {pathStart: this.pathStart, pathEnd: this.pathEnd, ease: 'power3.inOut', duration: 2.5, immediateRender: false}, 0); //This doesn't always look 100% perfect on the right side of the loop start.
        this.morphTweenReset.to(this, {pathSpeed: 1, ease: 'power3.inOut', duration: 0.1, immediateRender: false}, 2); //This doesn't always look 100% perfect on the right side of the loop start.
    }

    setupMorphTween = () => {
        this.morphTween = gsap.timeline({paused: true});
        this.morphTween.to(this, {speedUpPathValue: 1.5, ease: 'power4.inOut', duration: 4, immediateRender: false}, 0);
        this.morphTween.to(this, {pathStart: 1, pathEnd: 1, ease: 'power3.inOut', duration: 2.5, immediateRender: false}, 0); //This doesn't always look 100% perfect on the right side of the loop start.
        this.morphTween.to(this, {pathSpeed: 1, ease: 'power3.inOut', duration: 0.1, immediateRender: false}, 2); //This doesn't always look 100% perfect on the right side of the loop start.
        this.blobs.forEach((blob, index) => {
            this.morphTween.to(blob, {
                offsetX: blob.origOffsetX * Globals.MORPH_OFFSET_FACTOR,
                offsetY: blob.origOffsetY * Globals.MORPH_OFFSET_FACTOR,
                ease: 'sine.inOut',
                duration: 3,
                onUpdate: blob.updateOffset
            }, 1);
            this.morphTween.to(blob.el, {alpha: 0, ease: 'sine.inOut', duration: 1}, 0);
            this.morphTween.to(blob.elOutline, {alpha: 1, ease: 'sine.inOut', duration: 1}, 0);

        });

        //To results tween:
        this.morphTweenResult = gsap.timeline({paused: true});
        this.morphTweenResult.fromTo(this, {speedUpPathValue: 1.5}, {
            speedUpPathValue: this.toRecordingSpeedFactor === 1 ? 5 : 3,
            ease: 'power3.inOut',
            duration: 4.5 * this.toRecordingSpeedFactor,
            immediateRender: false
        }, 0);

        this.setupMorphAnimations();

    }

    private pathWrap = gsap.utils.pipe(gsap.utils.wrap(0, 1)); // TODO: @make this wrap from 0.9 -> 0.33;
    private pathStart = 0.95;
    private pathEnd = 0.35;
    private pathSpeed = 3;


    setupBlobAnimations = () => {
        var singleDuration = this.revolutionDuration / this.blobs.length;
        let progressStep = (singleDuration / this.revolutionDuration);
        let point: any;
        if (Globals.DEBUG) {
            this.timelineTweens.forEach((tween) => tween.kill());
            this.timelineTweens = [];
        }
        const factor = Globals.winW / this.motionPathW;
        const offsetX = Globals.winW - this.motionPathW;

        let progressValue = 0;
        this.blobs.forEach((blob, index) => {
            // blob.container.x = path[0].x;
            // blob.container.y = path[0].y;

            var proxy = {
                value: 0,
                target: blob.container,
                blob: blob,
                startProgress: index * progressStep,
                pathChoice: seedrandom(this.landingPathSeedValue + index)() >= 0.5,
                speed: 1 + gsap.utils.snap((seedrandom(5 + index)() * 10), 1)
            };
            blob.startProgress = proxy.startProgress;
            var randomSpeedMultiplier = gsap.utils.mapRange(0, 1, 0.7, 1, gsap.parseEase('power4.out')(seedrandom(1 + index)()));
            blob.moveTimeline = gsap.timeline({
                delay: index * singleDuration,
                // repeatDelay: this.revolutionDuration - (this.revolutionDuration * randomSpeedMultiplier),
                repeat: -1,
                // onRepeat: () => console.log('complete')
            });

            blob.moveTimeline.to(proxy, {
                value: 1, //gsap.utils.random(0.33, 0.5), //determines how much of a full revolution the blob makes, 1 === full circle:
                duration: this.revolutionDuration/* * randomSpeedMultiplier*/,
                /*delay:this.revolutionDuration - (this.revolutionDuration * randomSpeedMultiplier) - index * singleDuration,*/
                /*repeatDelay: this.revolutionDuration - (this.revolutionDuration * randomSpeedMultiplier) - index * singleDuration,*/
                ease: "none",
                immediateRender: true,
                onUpdate: () => {
                    if (this.currRawPath) {
                        progressValue = (this.pathStart + this.pathWrap((proxy.value * (this.pathSpeed)) + this.speedUpPathValue + blob.speed + blob.speedUpValue * this.pathSpeed) * this.pathEnd) % 1;
                        point = MotionPathPlugin.getPositionOnPath(proxy.pathChoice ? this.currRawPath : this.currRawPath2, progressValue, this.PARAMS.alignBlobs);
                        if (this.PARAMS.alignBlobs) {
                            proxy.target.rotation = point.angle * DEG_TO_RAD;
                        }
                        /*                    if (this.PARAMS.rotateBlobs) {
                                                proxy.blob.containerInnerWrapper.rotation = this.rotationValue/!* + (this.PARAMS.alignBlobs ? point.angle * DEG_TO_RAD : 0)*!/;
                                            }*/
                        proxy.target.x = point.x * factor;
                        proxy.target.y = point.y;
                    }
                }
            });
            // blob.moveTimeline.to(blob.container.scale, {x: blob.container.scale.x* 2, y: blob.container.scale.y*2, duration: this.revolutionDuration, immediateRender: true}, 0);
            blob.moveTimeline.progress(proxy.startProgress);
            this.blobsTimelines.push(blob.moveTimeline);
        });
        gsap.to(this, {
            blobsProgressTracker: 1,
            duration: this.revolutionDuration,
            ease: "none",
            immediateRender: true, repeat: -1
        });
    }

    animate = () => {
        stats.begin();
        Globals.MouseMoveTracker.raf();
        // this.mouseSpeedUpValue += Globals.MouseMoveTracker.mouse.distanceFromCenter / 2500;
        if (this.activeStage === this.stage) {
            this.ticker += 0.1;
            // if (this.ANIMATE_PATHS) {
            //     this.blobs.forEach(blob => blob.rafSpline());
            // }
            if (this.recordingUI.container.visible) {
                this.recordingUI.rafSpline();
            }
        }
        if (this.activeStage !== null) {
            this.app.render();
        }
        // this.app.ticker.update();
        stats.end();
    }

    private resize = () => {
        this.blobs.forEach(blob => blob.resize());
        this.recordingUI.resize();
        this.emotions.resize();
        this.mySongs.resize();
        this.setupMorphPaths();
        this.setupMorphAnimations();
    }

    private toRecordingSpeedFactor = 0.5;
    public gotoStep = (number: number, activePredictions?: any[]) => {
        if (number === StateManager.STEP_LANDING) {
            this.resize();
            this.blobs.forEach((blob, index) => {
                blob.resetForExperience(initOutlined[index]);
                blob.containerHoverWrapper.interactive = false;
            });
            gsap.to(this, {
                speedUpPathValue: 0, ease: 'power3.inOut', duration: 2.5, immediateRender: true
            });
            this.morphTweenResult.restart();
            this.morphTweenResult.pause();
            this.morphTween.restart();
            this.morphTween.pause();
        } else if (number === StateManager.STEP_EXPLORE_EMOTIONS) {
            gsap.to(this, {
                speedUpPathValue: 0.5, ease: 'power3.inOut', duration: 2.5, immediateRender: true, onComplete: () => {
                    // @ts-ignore
                    this.blobs.forEach((blob) => blob.containerHoverWrapper.interactive = true);
                }
            });
        } else if (number === StateManager.STEP_TRY_YOUR_OWN) {
            if (StateManager.ACTIVE_STEP === StateManager.STEP_SONG_MATCH || StateManager.ACTIVE_STEP === StateManager.STEP_RESULT) {
                this.blobs.forEach((blob, index) => {
                    blob.resetAnimation(index);
                    gsap.to(blob.moveTimeline, {progress: /*(*/blob.startProgress/* + this.blobsProgressTracker) % 1*/, duration: 1.5, ease: 'power4.inOut'});
                });
                this.morphTweenResult.pause();
                gsap.to(this.morphTweenResult, {progress: 0, duration: 2, ease: 'power1.out'})
            } else {
                this.blobs.forEach((blob) => blob.toRecording());
                gsap.delayedCall(3, () => {
                    if (!Globals.STATE_MANAGER.transitioning)
                        this.recordingUI.show();
                })
                this.morphTween.play();
            }
        } else if (number === StateManager.STEP_RESULT) {
            // this.morphTween.play();
            var snapProgresses: number[] = activePredictions.map((pred, index) => 1 - (index * (1 / activePredictions.length)));

            // console.log(snapProgresses);
            this.blobs.forEach((blob, index) => {
                const prediction = activePredictions.find((result, index) => {
                    if (result[0].toLowerCase() === blob.nameLowercased) {
                        return blob;
                    }
                    return false;
                });
                if (blob.isHighlighted && prediction) {
                    var snapToProgress = gsap.utils.snap(snapProgresses, Math.ceil(blob.moveTimeline.progress() * activePredictions.length) / activePredictions.length);
                    snapProgresses.splice(snapProgresses.indexOf(snapToProgress), 1);
                    // console.log(blob.moveTimeline, blob.moveTimeline.progress(), snapToProgress, snapProgresses, Math.ceil(blob.moveTimeline.progress() * activePredictions.length) / activePredictions.length);
                    gsap.to(blob.moveTimeline, {progress: snapToProgress, duration: 4 * this.toRecordingSpeedFactor, ease: 'power4.inOut'});
                    blob.animateToResult(prediction, this.toRecordingSpeedFactor);
                } else {
                    blob.hide(0);
                }
            });

            this.morphTweenResult.play();
            /*this.blobs.forEach((blob, index) => {
                this.morphTweenResult.to(blob.el, {alpha: 0, ease: 'sine.inOut', duration: 1}, 0);
                this.morphTweenResult.to(blob.elOutline, {alpha: 1, ease: 'sine.inOut', duration: 1}, 0);
            });
            this.morphTweenResult.add(() => {
                this.recordingUI.show();
            }, 3);*/
        }
    }

    private setupBlobRotation = () => {
        if (this.PARAMS.rotateBlobs) {
            if (this.rotationTween) {
                this.rotationTween.kill();
                this.rotationValue = 0;
            }
            //this.rotationTween = gsap.to(this, {rotationValue: Math.PI * 2, duration: this.PARAMS.rotationSpeed, repeat: -1, ease: 'none'});
        }
    }
}