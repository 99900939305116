import {IAPIResponse} from "./RecordingAPI";
import {Blob} from "./Blob";
import {Container} from "pixi.js";
import {gsap} from 'gsap';
import {Globals} from "../utils/Globals";

export class MySongsResultBlobs {
    private container: HTMLLIElement;
    private albumContainer: HTMLDivElement;
    private result: IAPIResponse;
    private blobs: Blob[] = [];
    public pixiContainer = new Container();
    private tweens: gsap.core.Tween[] = [];
    private stage: Container;

    constructor(result: IAPIResponse, container: HTMLLIElement, texture: any, mainBlobs: Blob[], stage: any) {
        this.result = result;
        this.container = container;
        this.stage = stage;
        this.albumContainer = this.container.querySelector('.SongUI-albumArt');

        let tweenStepProgress = 1 / this.result.predictions.length;
        let blobIndex = 0;
        let blobRotation = Math.PI * .75;
        // let blobRotation = Math.PI * -.25;
        mainBlobs.forEach((blob, index) => {
            if ((this.result.predictions as unknown as Array<any>).find((result) => result[0].toLowerCase() === blob.nameLowercased)) {
                const blobResult = new Blob(texture, blob.path, 0.8, blob.origOffsetX * 0.1, blob.origOffsetY * 0.1, false, false, false);
                this.pixiContainer.addChild(blobResult.container);
                this.blobs.push(blobResult);
                blobResult.container.pivot.x = (208 * 0.28);
                blobResult.container.pivot.y = (208 * 0.28);

                blobResult.containerInner.position.x = blobResult.origOffsetX;
                blobResult.containerInner.position.y = blobResult.origOffsetY;
                blobResult.containerInner.scale.x = 0.8;
                blobResult.containerInner.scale.y = 0.8;

//                blobResult.containerInnerWrapper.rotation = (Math.PI * 1.5 + gsap.utils.random(0, 0.9)) * (tweenStepProgress * blobIndex)
/*                gsap.to(blobResult.containerInnerWrapper, {
                    rotation: Math.random() >= 0.5 ? '+=' + Math.PI * 2 : '-=' + Math.PI * 2,
                    duration: gsap.utils.random(150, 200, 3),
                    repeat: -1,
                    ease: 'none'
                });*/
                blobResult.containerInnerWrapper.rotation = blobRotation;
                // blobRotation -= Math.PI * -0.6;
                // blobResult.container.rotation = Math.PI * 2 * tweenStepProgress * blobIndex;
                const tween = gsap.to(blobResult.container, {rotation: Math.PI * 2, repeat: -1, ease: 'none', duration: 150});
                tween.progress(tweenStepProgress * blobIndex);
                this.tweens.push(tween);
                blobIndex++;
            }
        });
        this.resize();


    }

    resize = () => {
        const rect = this.albumContainer.getBoundingClientRect();
        rect.y -= this.stage.y
        this.pixiContainer.position.x = rect.x + rect.width * 0.5;
        this.pixiContainer.position.y = rect.y + rect.height * 0.5;
        this.pixiContainer.scale.x = this.pixiContainer.scale.y = rect.width / 208;
    }
}