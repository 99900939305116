import {Globals} from "../utils/Globals";
import {pointsInPath} from '@georgedoescode/generative-utils'
import {BLEND_MODES, Container, Graphics, Matrix, Polygon, Texture, utils, Point, Rectangle} from "pixi.js";
import {drawSpline, formatPoints} from "../utils/Helpers";
import {gsap} from 'gsap';
import {CustomEase} from 'gsap/CustomEase';
import {CustomWiggle} from 'gsap/CustomWiggle';
import {RatioUtil} from "../utils/RatioUtil";
import {SOUND_SAMPLES} from "./BlobData";
import {SlowMo} from "gsap/EasePack";

const {SimplexNoise} = require('simplex-noise');
const seedrandom = require('seedrandom');


const simplex = new SimplexNoise();

function noise(x, y) {
    return simplex.noise2D(x, y);
}

function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

gsap.registerPlugin(CustomEase, CustomWiggle, SlowMo);

export class Blob {
    private fill = 0x000000;
    public fillString: string;
    public container: Container = new Container();
    public containerInner: Container = new Container();
    public containerInnerWrapper: Container = new Container();
    public containerHoverWrapper: Container = new Container();
    public containerAmbientMove: Container = new Container();
    public el: Graphics;
    public elOutline: Graphics;
    private texture: Texture;
    private ticker = 0;
    private points: any;
    private flatPoints: number[];
    public tension = 1;
    public noiseStep = 0;
    public maxOffset = 0;
    private DEBUG = false;
    private textureMatrix = new Matrix();
    public offsetX: number;
    public offsetY: number;
    public origOffsetX: number;
    public origOffsetY: number;
    private textureFill: { color: number; texture: Texture; alpha: number, matrix: Matrix };
    // public noiseStep = 0;
    // public maxOffset = 0;
    private pathPointCount = 32; //Works best with power of two's, 4, 16, 32, 64...
    private hitAreaPointCount = 16; //Works best with power of two's, 4, 16, 32, 64...
    public pointMorphSkips = 2;
    private origin = new Point();
    public path: SVGPathElement;
    private totalPoints = 0;

    private _outline = false;
    private debugRect: Graphics;

    private origScale = 1;
    private scale = 1;
    private textureScaleFactor = 0.5;
    public readonly name: string;
    public readonly nameLowercased: string;
    public isHighlighted: boolean;
    private lineWidth: number;
    moveTimeline: gsap.core.Timeline;
    startProgress: number;
    private audioSamples: string[];
    private currAudioSampleIndex = 0;
    private origOutline: boolean;
    private hoverLabel: HTMLDivElement;

    private setLabelPositionX: Function;
    private setLabelPositionY: Function;
    private _showLabel: boolean = false;
    public speedUpValue = 0;
    public speed = 0;
    private ambientMove: number;
    private index = -1;
    private ambientTweens: gsap.core.Tween[] = [];
    private ambientSpeedTween: gsap.core.Tween;
    private ambientTweenOffset = 25
    private randomBool = seedrandom(this.index + 58)() > 0.5;
    private resultBlobScaleAdd: number = 0;
    private resultBlobScaleAddFactor: number = 0;
    private delayedClickButtonOut: gsap.core.Tween;
    private buttonOver: boolean = false;
    private hoverWiggleTweens: gsap.core.Tween[] = [];


    constructor(texture, path: SVGPathElement, scale: number, offsetX: number, offsetY: number, outline: boolean, rotate = true, interactive: boolean = true, index = 0, ambientMove = 0) {
        this.index = index;
        this.ambientMove = ambientMove;
        this.offsetX = this.origOffsetX = offsetX;
        this.offsetY = this.origOffsetY = offsetY;
        this.texture = texture;
        this.scale = this.origScale = scale;
        this.fill = utils.string2hex(path.getAttribute('fill'));
        this.fillString = path.getAttribute('fill');
        this.name = path.getAttribute('id');
        this.nameLowercased = this.name === 'Surprise' ? 'surprise (positive)' : this.name.toLowerCase();
        this.audioSamples = SOUND_SAMPLES[this.name.toLowerCase()];
        Globals.BLOB_NAMES.push(this.nameLowercased);
        this.path = path;
        this.el = new Graphics();
        this.elOutline = new Graphics();
        this.container.addChild(this.containerInner);
        this.containerInner.addChild(this.containerAmbientMove);
        this.containerAmbientMove.addChild(this.containerInnerWrapper);
        this.containerInnerWrapper.addChild(this.containerHoverWrapper);
        this.containerHoverWrapper.addChild(this.el);
        this.containerHoverWrapper.addChild(this.elOutline);
        this.el.blendMode = BLEND_MODES.MULTIPLY;
        this.elOutline.blendMode = BLEND_MODES.MULTIPLY;
        this.el.pluginName = 'blob';
        this.elOutline.pluginName = 'batch';

        if (interactive) {
            this.hoverLabel = document.createElement('div');
            this.hoverLabel.classList.add('EmotionHoverLabel')
            this.hoverLabel.textContent = /*index + ' : ' + */this.name;
            document.querySelector('.CanvasContent').appendChild(this.hoverLabel);
            gsap.set(this.hoverLabel, {opacity: 0, force3D: true, x: 0, y: 0});
            this.setLabelPositionX = gsap.quickSetter(this.hoverLabel, 'x', 'px');
            this.setLabelPositionY = gsap.quickSetter(this.hoverLabel, 'y', 'px');
            // this.showLabel = true;
        }


        this.outline = outline;
        this.origOutline = outline;

        this.textureFill = {texture: this.texture, color: this.fill, alpha: 1.0, matrix: this.textureMatrix}


        // for every point...
        this.setupPoints();
        this.rafSpline();


        this.containerInnerWrapper.pivot.x = this.el.width / 2;
        this.containerInnerWrapper.pivot.y = this.el.height / 2;

        if (interactive) {
            this.containerHoverWrapper.position.x = this.el.width / 2;
            this.containerHoverWrapper.position.y = this.el.height / 2;
            this.containerHoverWrapper.pivot.x = this.el.width / 2;
            this.containerHoverWrapper.pivot.y = this.el.height / 2;
        }

        this.containerInner.position.x = offsetX * Globals.blobScaleFactor;
        this.containerInner.position.y = offsetY * Globals.blobScaleFactor;
        this.containerInner.scale.x = this.scale * Globals.blobScaleFactor;
        this.containerInner.scale.y = this.scale * Globals.blobScaleFactor;
        if (this.DEBUG) {
            this.addDebugRect();
        }


        if (interactive) {
            // @ts-ignore
            this.containerHoverWrapper.interactive = false;
            // @ts-ignore
            this.containerHoverWrapper.buttonMode = true;
            // @ts-ignore
            this.containerHoverWrapper.on('pointerover', this.onButtonOver).on('pointerout', this.onButtonOut).on('pointerdown', () => {
                this.onButtonOver();
                this.delayedClickButtonOut = gsap.delayedCall(3, this.onButtonOut);
            });
            this.setupAmbientTweens();

            gsap.to(this.containerInnerWrapper, {
                rotation: Math.PI * (seedrandom(this.index + 58)() > 0.5 ? 0.05 : -0.05),
                ease: 'sine.inOut',
                duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 5)()),
                repeat: -1,
                yoyo: true
            });
            gsap.to(this.containerInnerWrapper.scale, {x: 1.14, y: 0.9, ease: 'sine.inOut', duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 5)()), repeat: -1, yoyo: true});
            gsap.to(this.containerAmbientMove.scale, {x: 1.1, y: 0.85, ease: 'sine.inOut', duration: gsap.utils.mapRange(0, 1, 2, 4, 1 - seedrandom(this.index + 5)()), repeat: -1, yoyo: true});
            if (this.ambientMove > 0) {
                const speedAdd = 1;
                this.ambientSpeedTween = gsap.to(this, {
                    speed: '+=' + speedAdd,
                    ease: 'sine.inOut',
                    duration: 150 * this.ambientMove,
                    repeat: -1,
                    delay: 150 * this.ambientMove * 0.25 * 0.125,
                    repeatDelay: 150 * this.ambientMove,
                    repeatRefresh: true,
                    onRepeat: () => {
                        console.log('repeat');
                    }
                });
            }
        } else {
            const progressStart = seedrandom(this.index + 3)();
            this.ambientTweenOffset = 10;
            this.setupAmbientTweens();
            this.ambientTweens.forEach(tween => tween.progress(progressStart))

            /*            gsap.to(this.containerInnerWrapper, {
                            rotation: Math.PI * (seedrandom(this.index + 58)() > 0.5 ? 0.05 : -0.05),
                            ease: 'sine.inOut',
                            duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 5)()),
                            repeat: -1,
                            yoyo: true
                        }).progress(progressStart);*/
            gsap.to(this.containerInnerWrapper.scale, {
                x: 1.1,
                y: 0.9,
                ease: 'sine.inOut',
                duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 5)()),
                repeat: -1,
                yoyo: true
            }).progress(progressStart);
            gsap.to(this.containerAmbientMove.scale, {
                x: 1.1,
                y: 0.85,
                ease: 'sine.inOut',
                duration: gsap.utils.mapRange(0, 1, 2, 4, 1 - seedrandom(this.index + 5)()),
                repeat: -1,
                yoyo: true
            }).progress(progressStart);
            if (rotate) {
                gsap.to(this.containerInnerWrapper, {
                    rotation: Math.random() >= 0.5 ? Math.PI * 2 : -Math.PI * 2,
                    duration: gsap.utils.random(150, 200, 3),
                    repeat: -1,
                    ease: 'none'
                }).progress(progressStart);
            }
        }
        this.resize();
    }

    private setupAmbientTweens = () => {
        this.ambientTweens.forEach(tween => tween.kill());
        this.ambientTweens = [];
        this.ambientTweens.push(gsap.fromTo(this.containerInnerWrapper.position, {y: 0},{
            y: this.ambientTweenOffset * Globals.blobScaleFactor,
            ease: 'sine.inOut',
            duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 5)()),
            repeat: -1,
            yoyo: true
        }));
        this.ambientTweens.push(gsap.fromTo(this.containerInnerWrapper.position, {x: 0},{
            x: this.ambientTweenOffset * Globals.blobScaleFactor,
            ease: 'sine.inOut',
            duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 8)()),
            repeat: -1,
            yoyo: true
        }));
    }

    public updateOffset = () => {
        this.containerInner.position.x = this.offsetX * Globals.blobScaleFactor;
        this.containerInner.position.y = this.offsetY * Globals.blobScaleFactor;
    }

    get outline(): boolean {
        return this._outline;
    }

    set outline(value: boolean) {
        this._outline = value;

        if (this._outline) {
            this.el.alpha = 0;
            this.elOutline.alpha = 1;
        } else {
            this.el.alpha = 1;
            this.elOutline.alpha = 0;
        }
    }

    public playAudioSample() {
        Globals.BLOB_AUDIO_PLAYER.playSound(this.audioSamples[this.currAudioSampleIndex]);
        this.currAudioSampleIndex++;
        if (this.currAudioSampleIndex >= this.audioSamples.length) {
            this.currAudioSampleIndex = 0;
        }
    }

    public stopAudioSample() {
        Globals.BLOB_AUDIO_PLAYER.stopSound();
    }


    get showLabel(): boolean {
        return this._showLabel;
    }

    set showLabel(value: boolean) {
        if (value !== this.showLabel) {
            if (value === true) {
                gsap.ticker.add(this.updateLabelPosition);
            } else {
                gsap.ticker.remove(this.updateLabelPosition);
            }
            this._showLabel = value;
        }
    }

    private hoverPosition = new Point();
    private updateLabelPosition = () => {
        // @ts-ignore
        this.containerInnerWrapper.getGlobalPosition(this.hoverPosition, false);
        // console.log(this.el._bounds);
        // console.log(this.bounds);
        this.setLabelPositionX(this.hoverPosition.x - 100/* - (this.containerHoverWrapper.position.x * 2 * this.containerInner.scale.x * 0.5)*/);
        this.setLabelPositionY(this.hoverPosition.y - (this.containerHoverWrapper.position.y * 1.5 * this.containerInner.scale.y));
    }

    private onButtonOver = () => {
        if (this.buttonOver) {
            return false;
        }
        if (this.delayedClickButtonOut) {
            this.delayedClickButtonOut.kill();
            this.delayedClickButtonOut = null;
        }
        this.buttonOver = true;
        this._outline = false;
        gsap.killTweensOf(this.el, null, true);
        gsap.killTweensOf(this.elOutline, null, true);
        // gsap.killTweensOf(this.containerInnerWrapper.scale, null, true);
        gsap.to(this.el, {alpha: 1, duration: 0.3, ease: 'none'});
        gsap.to(this.elOutline, {alpha: 0, duration: 0.3, ease: 'none'});
        // gsap.to(this.containerInnerWrapper.scale, {x: 1.15, y: 1.15, duration: 1, ease: 'power3.out'});
        // this.rafSpline();
        this.playAudioSample();
        gsap.killTweensOf(this.hoverLabel);
        this.showLabel = true;
        gsap.killTweensOf(this.containerHoverWrapper.scale);
        gsap.killTweensOf([this.el.position, this.elOutline.position], null, true);
        this.hoverWiggleTweens.forEach((tween => tween.kill()));
        var tl = gsap.timeline({repeat: -1, yoyo: true});
        tl.to(this.containerHoverWrapper.scale, {x: this.randomBool ? 1.2 : 1.3, ease: 'sine.inOut', duration: 1 / 2}, 0);
        tl.to(this.containerHoverWrapper.scale, {y: this.randomBool ? 1.3 : 1.2, ease: 'sine.inOut', duration: 2 / 2}, 0);
        tl.to(this.containerHoverWrapper.scale, {x: this.randomBool ? 1.15 : 1.25, ease: 'sine.inOut', duration: 1 / 2}, 1 / 2);
        this.hoverWiggleTweens = [];
        this.hoverWiggleTweens.push(gsap.to([this.el.position, this.elOutline.position], {x: 5, y: 10, ease: 'wiggle({type:easeInOut, wiggles:6})', duration: 1.5, repeat: -1, yoyo: false}));
        this.hoverWiggleTweens.push(gsap.to([this.el, this.elOutline], {rotation: 0.05, ease: 'wiggle({type:easeInOut, wiggles:6})', duration: 1.5, repeat: -1, yoyo: false}));
        gsap.to(this.hoverLabel, {opacity: 1, force3D: true, duration: 1, ease: 'sine.inOut'});
    }

    private onButtonOut = () => {
        if (this.buttonOver === false) {
            return false;
        }
        if (this.delayedClickButtonOut) {
            this.delayedClickButtonOut.kill();
            this.delayedClickButtonOut = null;
        }
        this.buttonOver = false;
        if (this.origOutline) {
            this._outline = true;
            gsap.killTweensOf(this.el, null, true);
            gsap.killTweensOf(this.elOutline, null, true);
            gsap.to(this.el, {alpha: 0, duration: 0.3, ease: 'none'});
            gsap.to(this.elOutline, {alpha: 1, duration: 0.3, ease: 'none'});
        }
        gsap.killTweensOf(this.hoverLabel);
        gsap.killTweensOf(this.containerHoverWrapper.scale);
        gsap.killTweensOf([this.el.position, this.elOutline.position], null, true);
        this.hoverWiggleTweens.forEach((tween => tween.kill()));
        // gsap.killTweensOf(this.containerHoverWrapper);
        gsap.to(this.containerHoverWrapper.scale, {x: 1, y: 1, ease: 'sine.out', duration: 0.3});
        gsap.to([this.el.position, this.elOutline.position], {x: 0, y: 0, ease: 'sine.out', duration: 0.3});
        gsap.to([this.el, this.elOutline], {rotation: 0, ease: 'sine.out', duration: 0.3});
        // gsap.to(this.containerHoverWrapper, {rotation: 0, ease: 'sine.out', duration: 0.3});
        gsap.to(this.hoverLabel, {
            opacity: 0, force3D: true, duration: 0.3, onComplete: () => {
                this.showLabel = false;
            }
        });
        // gsap.killTweensOf(this.containerInnerWrapper.scale, null, true);
        // gsap.to(this.containerInnerWrapper.scale, {x: 1, y: 1, duration: 0.75, ease: 'power3.out'});
        // this.rafSpline();
    }

    private setupPoints = () => {
        this.points = pointsInPath(this.path, this.pathPointCount);
        this.points = this.points.map(point => {
            return {
                x: point.x,
                y: point.y,
                // we need to keep a reference to the point's original point for when we modulate the values later
                originX: point.x,
                originY: point.y,
                // more on this in a moment!
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000
            }
        });
        // @ts-ignore
        this.containerHoverWrapper.hitArea = new Polygon([...pointsInPath(this.path, this.hitAreaPointCount)].map(({x, y}) => [x, y]).flat());
        this.flatPoints = formatPoints(this.points, true);
        this.totalPoints = this.points.length;
        this.points = formatPoints(this.points, true, false);
    }

    public rafSpline = () => {
        this.el.clear();
        this.elOutline.clear();

        this.elOutline.lineStyle(this.lineWidth, this.fill, 1, 1);
        this.elOutline.pluginName = 'batch'
        // this.el.lineStyle(2 / this.el.scale.x, this.fill, 1);
        this.textureMatrix.a = 1 / this.containerInner.scale.x * this.textureScaleFactor;
        this.textureMatrix.d = 1 / this.containerInner.scale.y * this.textureScaleFactor;
        // console.log(this.textureMatrix);
        // this.el.pluginName = 'blob'
        this.el.beginTextureFill(this.textureFill);
        this.buildShape();
        this.elOutline.endFill();
        this.el.endFill();
    }

    private buildShape = () => {
        for (let i = 0; i < this.totalPoints; i++) {
            if (i % this.pointMorphSkips === 0) {
                const point = this.points[i];

                // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
                const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
                const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
                // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
                const x = map(nX, -1, 1, point.originX - this.maxOffset, point.originX + this.maxOffset);
                const y = map(nY, -1, 1, point.originY - this.maxOffset, point.originY + this.maxOffset);

                // update the point's current coordinates
                point.x = x;
                point.y = y;
                this.flatPoints[i * 2] = x;
                this.flatPoints[i * 2 + 1] = y;
                // progress the point's x, y values through "time"
                point.noiseOffsetX += this.noiseStep;
                point.noiseOffsetY += this.noiseStep;
            }
        }
        drawSpline(this.flatPoints, this.tension, this.el, this.elOutline);
    }

    public resize = () => {
        this.origin.x = Globals.winW / 2;
        this.origin.y = Globals.winH / 2;

        this.containerInnerWrapper.pivot.x = this.el.width / 2;
        this.containerInnerWrapper.pivot.y = this.el.height / 2;

        this.containerInner.position.x = this.offsetX * Globals.blobScaleFactor;
        this.containerInner.position.y = this.offsetY * Globals.blobScaleFactor;
        this.resultBlobScaleAdd = Globals.RESULT_DIAMETER > 200 ? 0 : 1 - Globals.blobScaleFactor;
        this.containerInner.scale.x = (this.scale + this.resultBlobScaleAdd * this.resultBlobScaleAddFactor) * Globals.blobScaleFactor;
        this.containerInner.scale.y = (this.scale + this.resultBlobScaleAdd * this.resultBlobScaleAddFactor) * Globals.blobScaleFactor;
        this.lineWidth = Math.min(2 / this.containerInner.scale.x, 4);
        this.rafSpline();
        this.setupAmbientTweens();
    }

    public resizeFixedSize = (rect: DOMRect) => {
        this.origin.x = Globals.winW / 2;
        this.origin.y = Globals.winH / 2;

        this.containerInnerWrapper.pivot.x = this.el.width / 2;
        this.containerInnerWrapper.pivot.y = this.el.height / 2;

        this.containerInner.position.x = this.offsetX;
        this.containerInner.position.y = this.offsetY;


        const rectTransformed = RatioUtil.scaleToFit({width: this.el.width, height: this.el.height}, rect, true);
        this.scale = rectTransformed.width / this.el.width;
        this.containerInner.scale.x = this.scale;
        this.containerInner.scale.y = this.scale;
        this.container.position.x = rect.x + (this.el.width * 0.5 * this.scale) + ((rect.width - rectTransformed.width) * 0.5);
        this.container.position.y = rect.y + (this.el.height * 0.5 * this.scale) + ((rect.height - rectTransformed.height) * 0.5);


        /*        this.debugRect = new Graphics();
                this.container.addChild(this.debugRect);
                this.debugRect.beginFill(0xff2080, 0.8);
                this.debugRect.drawRect(0, 0, rect.width, rect.height);
                this.debugRect.endFill();*/
        this.lineWidth = Math.min(2 / this.containerInner.scale.x, 4);
        this.rafSpline();
        this.setupAmbientTweens();
    }

    private addDebugRect() {
        this.debugRect = new Graphics();
        this.container.addChild(this.debugRect);
        this.debugRect.beginFill(0xff2080, 0.8);
        this.debugRect.drawRect(0, 0, this.el.width, this.el.height);
        this.debugRect.endFill();
    }

    highlighted = (value: boolean) => {
        if (value !== this.isHighlighted) {
            this.isHighlighted = value;
            if (value) {
                this._outline = false;
                gsap.killTweensOf(this.el, null, true);
                gsap.killTweensOf(this.elOutline, null, true);
                gsap.killTweensOf(this.containerInnerWrapper.scale, null, true);
                gsap.to(this.el, {alpha: 1, duration: 0.3, ease: 'none'});
                gsap.to(this.elOutline, {alpha: 0, duration: 0.3, ease: 'none'});
                gsap.to(this.containerInnerWrapper.scale, {x: 1.15, y: 1.15, duration: 1, ease: 'power3.out'});
            } else {
                this._outline = true;
                gsap.killTweensOf(this.el, null, true);
                gsap.killTweensOf(this.elOutline, null, true);
                gsap.killTweensOf(this.containerInnerWrapper.scale, null, true);
                gsap.to(this.el, {alpha: 0, duration: 0.3, ease: 'none'});
                gsap.to(this.elOutline, {alpha: 1, duration: 0.3, ease: 'none'});
                gsap.to(this.containerInnerWrapper.scale, {x: 1, y: 1, duration: 0.75, ease: 'power3.out'});
            }
        }
    }

    hide = (index: number) => {
        gsap.killTweensOf(this.el, null, true);
        gsap.killTweensOf(this.elOutline, null, true);
        gsap.to([this.el, this.elOutline], {alpha: 0, duration: 1 + index * -0.05, ease: 'none', delay: index * 0.05});
    }

    toRecording = () => {
        this.onButtonOut();
        // @ts-ignore
        this.containerHoverWrapper.interactive = false
        if (this.ambientSpeedTween) {
            this.ambientSpeedTween.kill();
            var speedTo = Math.ceil(this.speed);
            gsap.to(this, {speed: speedTo, duration: 1.5 * (1 - this.speed % 1), ease: 'power3.inOut', delay: 1});
        }
    }

    animateToResult(prediction: any, toRecordingSpeedFactor: number) {
        this.ambientTweens.forEach(tween => tween.kill());
        gsap.killTweensOf(this.containerInner.scale);
        this.ambientTweenOffset = 10;
        gsap.to(this.containerInnerWrapper.position, {
            x: 0, y: 0,delay: 0.75 * toRecordingSpeedFactor, duration: 1.5 * toRecordingSpeedFactor, onComplete: () => {
                gsap.to(this.containerInnerWrapper.position, {
                    y: 10 * Globals.blobScaleFactor,
                    ease: 'sine.inOut',
                    duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 5)()),
                    repeat: -1,
                    yoyo: true
                });
                gsap.to(this.containerInnerWrapper.position, {
                    x: 10 * Globals.blobScaleFactor,
                    ease: 'sine.inOut',
                    duration: gsap.utils.mapRange(0, 1, 3, 7, seedrandom(this.index + 8)()),
                    repeat: -1,
                    yoyo: true
                });
            }
        });
        gsap.to(this, {offsetX: this.origOffsetX * 0.1, offsetY: this.origOffsetY * 0.1, ease: 'sine.inOut', duration: 3 * toRecordingSpeedFactor, onUpdate: this.updateOffset});
        const scaleTo = (1 - ((1 - prediction[1]) * 0.1));
        gsap.to(this, {scale: scaleTo, duration: 3 * toRecordingSpeedFactor, ease: 'sine.inOut'});
        this.resultBlobScaleAddFactor = 1;
        gsap.to(this.containerInner.scale, {x: (scaleTo + this.resultBlobScaleAdd * this.resultBlobScaleAddFactor) * Globals.blobScaleFactor, y: (scaleTo + this.resultBlobScaleAdd * this.resultBlobScaleAddFactor) * Globals.blobScaleFactor, duration: 3 * toRecordingSpeedFactor, ease: 'sine.inOut', onUpdate: this.rafSpline});
    }

    resetForExperience = (outline: boolean) => {
        gsap.killTweensOf(this.el, null, true);
        gsap.killTweensOf(this.elOutline, null, true);
        gsap.killTweensOf(this.containerInnerWrapper.scale, null, true);
        this.resultBlobScaleAddFactor = 0;
        var tl = gsap.timeline();
        tl.to(this.el, {alpha: outline ? 0 : 1, duration: 0.5, ease: 'none'}, 0.5);
        tl.to(this.elOutline, {alpha: outline ? 1 : 0, duration: 0.5, ease: 'none'}, 0.5);
        /*        if (this.isHighlighted) {
                } else {
                    tl.to(this.el, {alpha: 0, duration: 0.5, ease: 'none', delay: index * 0.025}, 1);
                    tl.to(this.elOutline, {alpha: 1, duration: 0.5, ease: 'none', delay: index * 0.025}, 1);
                }*/
        this._outline = outline;
        this.isHighlighted = outline ? false : true;
        this.highlighted(outline ? false : true);
        tl.to(this.containerInnerWrapper.scale, {x: 1, y: 1, duration: 2, ease: 'power3.out'}, 0);
        tl.to(this, {
            offsetX: this.origOffsetX * Globals.MORPH_OFFSET_FACTOR,
            offsetY: this.origOffsetY * Globals.MORPH_OFFSET_FACTOR,
            ease: 'sine.inOut',
            duration: 3,
            onUpdate: this.updateOffset
        }, 0);
        tl.to(this, {scale: this.origScale, duration: 2, ease: 'sine.inOut'}, 0);
        tl.to(this.containerInner.scale, {x: this.origScale * Globals.blobScaleFactor, y: this.origScale * Globals.blobScaleFactor, duration: 2, ease: 'sine.inOut', onUpdate: this.rafSpline}, 0);
        gsap.killTweensOf(this.containerInnerWrapper.position, null, true);
        gsap.to(this.containerInnerWrapper.position, {
            x: 0, y: 0, duration: 1.5, onComplete: () => {
                this.ambientTweenOffset = 25;
                this.setupAmbientTweens();
            }
        });
    }

    resetAnimation = (index: number) => {
        gsap.killTweensOf(this.containerInner.scale);
        gsap.killTweensOf(this.el, null, true);
        gsap.killTweensOf(this.elOutline, null, true);
        gsap.killTweensOf(this.containerInnerWrapper.scale, null, true);
        this.resultBlobScaleAddFactor = 0;
        var tl = gsap.timeline();
            tl.to(this.el, {alpha: 0, duration: 0.5, ease: 'none'}, 0.5);
            tl.to(this.elOutline, {alpha: 1, duration: 0.5, ease: 'none'}, 0.5);
/*        if (this.isHighlighted) {
        } else {
            tl.to(this.el, {alpha: 0, duration: 0.5, ease: 'none', delay: index * 0.025}, 1);
            tl.to(this.elOutline, {alpha: 1, duration: 0.5, ease: 'none', delay: index * 0.025}, 1);
        }*/
        this._outline = true;
        this.isHighlighted = false;
        tl.to(this.containerInnerWrapper.scale, {x: 1, y: 1, duration: 2, ease: 'power3.out'}, 0);
        tl.to(this, {
            offsetX: this.origOffsetX * Globals.MORPH_OFFSET_FACTOR,
            offsetY: this.origOffsetY * Globals.MORPH_OFFSET_FACTOR,
            ease: 'sine.inOut',
            duration: 3,
            onUpdate: this.updateOffset
        }, 0);
        tl.to(this, {scale: this.origScale, duration: 2, ease: 'sine.inOut'}, 0);
        tl.to(this.containerInner.scale, {x: this.origScale * Globals.blobScaleFactor, y: this.origScale * Globals.blobScaleFactor, duration: 2, ease: 'sine.inOut', onUpdate: this.rafSpline}, 0);
        gsap.killTweensOf(this.containerInnerWrapper.position, null, true);
        gsap.to(this.containerInnerWrapper.position, {
            x: 0, y: 0, duration: 1.5, onComplete: () => {
                this.ambientTweenOffset = 25;
                this.setupAmbientTweens();
            }
        });
    }
}