import {Container, Texture} from "pixi.js";
import {Blob} from "./Blob";
import {gsap} from "gsap";
import {Globals} from "../utils/Globals";

export class EmotionsView {
    private _outer:HTMLElement = document.querySelector('.PagesWrapper');
    private _inner:HTMLElement = document.querySelector('#emotions');
    private container = document.querySelector('#emotions ul');
    private canvasRenderer = document.querySelector('.CanvasRenderer');
    private listItems = this.container.querySelectorAll('#emotions ul li');
    private listItemContainers = this.container.querySelectorAll('#emotions ul li div');
    public stage = new Container();
    private blobs: Blob[] = [];
    private listItemRects: DOMRect[] = [];
    private activeBlob: Blob;

    constructor(blobPaths: SVGPathElement[], texture: Texture) {
        this.listItems.forEach((li, index) => {
            const path = blobPaths.find((path) => li.textContent.toLowerCase() === path.getAttribute('id').toLowerCase());
            const blob = new Blob(texture, path, 1, 0, 0, false, true, false, index)
            this.blobs.push(blob);
            this.stage.addChild(blob.container);
            li.addEventListener('click', () => {
                console.log('click');
                gsap.delayedCall(3,() => this.mouseOut())
                this.mouseOver(index);
            })
            li.addEventListener('mouseenter', () => this.mouseOver(index))
            li.addEventListener('mouseleave', this.mouseOut)
        });
        window.addEventListener('scroll', this.onScroll);

        Globals.STATE_MANAGER.router.signalRouteChange.add((activeID:string) => {
            if (activeID !== 'emotions') {
                if (this.activeBlob) {
                    this.activeBlob.stopAudioSample();
                }
            }
        });
    }

    public resize = () => {
     //   console.log(this._inner.offsetHeight )


        this._outer.style.height = this._inner.offsetHeight + 160 + 'px';
        this.onScroll();
        this.listItems.forEach((li, index) => {
            const rect = this.listItemContainers[index].getBoundingClientRect();
            //const rect = MotionPathPlugin.convertCoordinates(li, this.canvasRenderer, {x: 0, y: 0});
            rect.y -= this.stage.y;
            this.listItemRects.push(rect);
            const blob = this.blobs[index];
            blob.resizeFixedSize(rect);
        })
    }

    private onScroll = () => {
        this.stage.y = -window.scrollY;
        gsap.set(this._inner, {y: -window.scrollY});
       /* gsap.to(this.container.parentNode, {duration: 2,
            y: window.scrollY
        });*/
    }

    private mouseOver = (index: number) => {
        if (this.activeBlob !== this.blobs[index]) {
            this.activeBlob = this.blobs[index];
            gsap.killTweensOf(this.activeBlob.containerInnerWrapper.scale);
            gsap.killTweensOf(this.activeBlob.container.scale);
            gsap.killTweensOf(this.activeBlob.el.position);
            gsap.killTweensOf(this.activeBlob.elOutline.position);
            gsap.killTweensOf(this.activeBlob.el);
            gsap.killTweensOf(this.activeBlob.elOutline);
            this.activeBlob.playAudioSample();
            /*        gsap.to(this.activeBlob.container.scale, {x: 1.2, ease: 'sine.inOut', duration: 0.75, repeat: -1, yoyo: true});
                    gsap.to(this.activeBlob.container.scale, {y: 1.3, ease: 'sine.inOut', duration: 1.5, repeat: -1, yoyo: true});*/

            var tl = gsap.timeline({repeat: -1, yoyo: true});
            tl.to(this.activeBlob.container.scale, {x: 1.3 * 0.9, ease: 'sine.inOut', duration: 1 / 2}, 0);
            tl.to(this.activeBlob.container.scale, {y: 1.2 * 0.9, ease: 'sine.inOut', duration: 2 / 2}, 0);
            tl.to(this.activeBlob.container.scale, {x: 1.25 * 0.9, ease: 'sine.inOut', duration: 1 / 2}, 1 / 2);
            gsap.to([this.activeBlob.el.position, this.activeBlob.elOutline.position], {x: 2.5, y: 5, ease: 'wiggle({type:easeInOut, wiggles:6})', duration: 1.5, repeat: -1, yoyo: false});
            gsap.to([this.activeBlob.el, this.activeBlob.elOutline], {rotation: 0.025, ease: 'wiggle({type:easeInOut, wiggles:6})', duration: 1.5, repeat: -1, yoyo: false});

            gsap.to(this.activeBlob.containerInnerWrapper.scale, {x: 1.07, y: 1.07, duration: 0.5, ease: 'power3.inOut'});
        }
    }

    private mouseOut = () => {
        console.log('out');
        gsap.killTweensOf(this.activeBlob.el.position);
        gsap.killTweensOf(this.activeBlob.elOutline.position);
        gsap.killTweensOf(this.activeBlob.el);
        gsap.killTweensOf(this.activeBlob.elOutline);
        gsap.killTweensOf(this.activeBlob.containerInnerWrapper.scale);
        gsap.to(this.activeBlob.containerInnerWrapper.scale, {x: 1, y: 1, duration: 0.75, ease: 'power3.inOut'});
        gsap.killTweensOf(this.activeBlob.container.scale);
        gsap.to(this.activeBlob.container.scale, {x: 1, y: 1, ease: 'sine.out', duration: 0.3});
        this.activeBlob = null;
    }
}