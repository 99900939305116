import {SpotifyTrackInfo} from "./RecordingAPI";
import {Globals} from "../utils/Globals";

export class SongUI {
    private container:HTMLDivElement = document.querySelector('.SongUI');
    private containerAlbumArt = document.querySelector('.SongUI-albumArt');
    private audioElement = this.container.querySelector('audio');
    private img = this.container.querySelector('img');
    private playButton = this.container.querySelector('.PlayButton');
    private songInfoContainer: HTMLAnchorElement = document.querySelector('.SongUI-info');
    private artistTitle = this.songInfoContainer.querySelector('h5');
    private trackTitle = this.songInfoContainer.querySelector('h3');
    // private trackDate = this.songInfoContainer.querySelector('span');
    private songUIButtons = Array.from(document.querySelectorAll('.SongUI-buttons .AnchorButton'));
    private shareButton = document.querySelector('.AnchorButton.shareButton');
    private shareButtonClose = document.querySelector('.NotificationUI-dialog.share button');
    constructor() {
        this.playButton.addEventListener('click', this.togglePlayPause);
        this.songInfoContainer.addEventListener('click', this.pause);
        this.img.onload = () => {
            this.img.classList.add('loaded');
        }
        this.audioElement.addEventListener('playing', () => {
            this.playButton.classList.add('playing')
        })
        this.audioElement.addEventListener('pause', () => {
            this.playButton.classList.remove('playing');
        })

        this.shareButton.addEventListener('click', () => {
           Globals.NOTIFICATIONS_UI.toggleShareDialog(true);
        });
        this.shareButtonClose.addEventListener('click', () => {
           Globals.NOTIFICATIONS_UI.toggleShareDialog(false);
        });

        Globals.STATE_MANAGER.router.signalRouteChange.add((activeID:string) => {
            if (activeID !== '/') {
                if (this.audioElement.src && this.audioElement.src !== '' && !this.audioElement.paused) {
                    this.audioElement.pause();
                }
            }
        });
    }

    public setSongInfo = (spotifyTrackInfo: SpotifyTrackInfo) => {
        console.log(spotifyTrackInfo);
        this.artistTitle.textContent = spotifyTrackInfo.artists.map((artist)  => artist.name).join(', ');
        this.trackTitle.textContent = spotifyTrackInfo.name;
        this.songInfoContainer.href = spotifyTrackInfo.external_urls.spotify;
        // this.trackDate.textContent = spotifyTrackInfo.album.release_date;
        if (this.img.src !== spotifyTrackInfo.album.images[0].url) {
            this.img.classList.remove('loaded');
            this.img.src = spotifyTrackInfo.album.images[0].url;
        }
        if (spotifyTrackInfo.preview_url && spotifyTrackInfo.preview_url !== '' && spotifyTrackInfo.preview_url !== null) {
            this.playButton.classList.remove('playing');
            this.audioElement.src = spotifyTrackInfo.preview_url;
            this.container.classList.remove('no-preview');
        } else {
            this.audioElement.src = '';
            this.container.classList.add('no-preview');
        }
    }

    private togglePlayPause = (event:MouseEvent) => {
        if (event) {
            event.preventDefault();
        }
        this.audioElement.paused ? this.audioElement.play() : this.audioElement.pause();
        return false;
    }

    private pause = () => {
        this.audioElement.pause();
    }

    showButton = (index:number) => {
        this.songUIButtons[index].classList.add('visible');
    }

    hideButton = (index:number) => {
        this.songUIButtons[index].classList.remove('visible');
    }

    showSongInfo = () => {
        this.containerAlbumArt.classList.add('visible');
        this.songInfoContainer.classList.add('visible');
        this.songUIButtons.forEach((button) => button.classList.add('visible'));
        if (this.audioElement.src && this.audioElement.src !== '') {
            console.log(this.audioElement.src)
            this.audioElement.play();
        }

        this.container.style.visibility = 'visible';
    }

    hideSongInfo = () => {
        this.audioElement.src = '';
        this.containerAlbumArt.classList.remove('visible');
        this.songInfoContainer.classList.remove('visible');
        this.songUIButtons.forEach((button) => button.classList.remove('visible'));

        this.container.style.visibility = 'hidden';
    }
}