import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);
export class TitleContainer {
    private element: HTMLDivElement;
    private title: HTMLHeadingElement;
    private subtitle: HTMLParagraphElement;
    private subtitleHelper: Element;
    private titleSplit: SplitText;
    constructor(element:HTMLDivElement) {
        this.element = element;
        this.title = this.element.querySelector('h1');
        this.subtitle = this.element.querySelector('p');
        this.subtitleHelper = this.element.querySelector('p.TitleContainer-helper');
        // this.titleSplit = new SplitText(this.title, {type: 'chars'});
    }

    public show = () => {
        this.element.classList.add('visible');
        this.element.classList.remove('hidden');
    }
    public hide = () => {
        this.element.classList.add('hidden');
    }
}