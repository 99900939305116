import {TitleContainer} from "./TitleContainer";
import {gsap} from 'gsap';
export class TitleManager {
    private titles: HTMLDivElement[];
    private titleContainers: TitleContainer[];
    private currentIndex = 0;
    private delayCall: gsap.core.Tween;

    constructor() {
        this.titles = Array.from(document.querySelectorAll('.TitleContainer'));
        this.titleContainers = this.titles.map((title, index) => {
            return new TitleContainer(title);
        });
        gsap.delayedCall(0.2, this.titleContainers[0].show);
    }

    public setVisibleTitle = (index:number, delay = 1.5) => {
        this.titleContainers[this.currentIndex].hide();
        this.currentIndex = index;
        if (this.delayCall) {
            this.delayCall.kill();
        }
        this.delayCall = gsap.delayedCall(delay, this.titleContainers[this.currentIndex].show);
    }
}