import {CanvasRenderer} from "./CanvasRenderer";
import {ResultUI} from "./ResultUI";
import {IAPIResponse, RecordingAPI} from "./RecordingAPI";
import {TitleManager} from "./TitleManager";
import {gsap} from 'gsap';
import {Globals} from "../utils/Globals";
import {InfoDialogs} from "./InfoDialogs";
import {Router} from "./Router";
export class StateManager {
    private canvasRenderer: CanvasRenderer;
    public resultUI: ResultUI;
    private recordingAPI: RecordingAPI;

    private learnMoreButton = document.querySelector('.AnchorButton');
    private startButton = document.querySelector('.step1-cta');
    private tryYourOwnRecordingButton = document.querySelector('.TryOwnRecordingButton');
    private viewYourSongMatchButton = document.querySelector('.ViewYourSongMatch');
    private tryAgainButton = document.querySelector('.SongUI-buttons .tryAgain');
    private titleManager: TitleManager;

    private JUMP_TO_STEP = 0;

    public static STEP_LANDING = 0;
    public static STEP_EXPLORE_EMOTIONS = 1;
    public static STEP_TRY_YOUR_OWN = 2;
    public static STEP_ANALYZING = 3;
    public static STEP_RESULT = 4;
    public static STEP_SONG_MATCH = 5;
    public static ACTIVE_STEP:number;
    public transitioning: boolean = false;
    private infoDialogs: InfoDialogs;
    public router: Router;

    constructor(router: Router, loadCallback:() => void) {
        this.router = router;
        Globals.STATE_MANAGER = this;//Order is important here.
        this.resultUI = new ResultUI();
        this.recordingAPI = new RecordingAPI(this.resultUI);
        this.canvasRenderer = new CanvasRenderer(this.recordingAPI, this.router, loadCallback);
        this.titleManager = new TitleManager();
        this.infoDialogs = new InfoDialogs();
        this.resultUI.blobs = this.canvasRenderer.blobs;

        this.learnMoreButton.addEventListener('click', (event) => this.onButtonClick(event, StateManager.STEP_EXPLORE_EMOTIONS));
        this.tryYourOwnRecordingButton.addEventListener('click', (event) => this.onButtonClick(event, StateManager.STEP_TRY_YOUR_OWN));
        this.viewYourSongMatchButton.addEventListener('click', (event) => this.onButtonClick(event, StateManager.STEP_SONG_MATCH));
        this.tryAgainButton.addEventListener('click', (event) => this.onButtonClick(event, StateManager.STEP_TRY_YOUR_OWN));
        if (this.JUMP_TO_STEP > 0) {
            this.jumpToStep(this.JUMP_TO_STEP);
        } else {
            gsap.delayedCall(0.5, () => {
                this.learnMoreButton.classList.add('visible');
            });
        }
    }


    private jumpToStep = (index:number) => {
        for (let i = 1; i < index + 1; i++) {
            this.gotoStep(i);
        }
    }

    private onButtonClick = (event: Event, number: number) => {
        if (event) {
            event.preventDefault();
        }
        this.gotoStep(number);
    }

    public gotoStep = (number: number) => {
        if (Globals.STATE_MANAGER.transitioning)
            return false;

        if (number === StateManager.STEP_LANDING) {
            this.startButton.classList.remove('hidden');
            gsap.delayedCall(1, () => {
                this.startButton.classList.add('visible');
            })
            this.canvasRenderer.recordingUI.hide(true);
            this.viewYourSongMatchButton.classList.remove('hidden', 'visible');
            this.tryYourOwnRecordingButton.classList.remove('hidden', 'visible');
            this.titleManager.setVisibleTitle(0);
            this.infoDialogs.hide();
            Globals.NOTIFICATIONS_UI.hideDialogs();
            if (StateManager.ACTIVE_STEP === StateManager.STEP_SONG_MATCH ||
                StateManager.ACTIVE_STEP === StateManager.STEP_RESULT) {
                this.resultUI.hideCurrentResult();
                this.resultUI.clearResults();
                this.infoDialogs.hide();
                this.viewYourSongMatchButton.classList.remove('visible');
                this.viewYourSongMatchButton.classList.remove('hidden');
            }

        } else if (number === StateManager.STEP_EXPLORE_EMOTIONS) {
            var getWebbyWinner = document.body.querySelector('.webbyWinner');
            gsap.to(getWebbyWinner, {duration: 0.3, y: 250, ease: 'power1.in'});

            this.learnMoreButton.classList.add('hidden');
            gsap.delayedCall(3, () => {
                this.tryYourOwnRecordingButton.classList.add('visible');
            })
            this.titleManager.setVisibleTitle(1);
        } else if (number === StateManager.STEP_TRY_YOUR_OWN) {
            Globals.STATE_MANAGER.transitioning = true;
            let titleDelay = 3;
            if (StateManager.ACTIVE_STEP === StateManager.STEP_SONG_MATCH) {
                this.resultUI.hideCurrentResult();
                gsap.delayedCall(1, ()=> {
                    this.canvasRenderer.recordingUI.show();
                    Globals.STATE_MANAGER.transitioning = false;
                });
                this.infoDialogs.hide();
                this.viewYourSongMatchButton.classList.remove('visible');
                this.viewYourSongMatchButton.classList.remove('hidden');
                titleDelay = 1.5;
            } else if (StateManager.ACTIVE_STEP === StateManager.STEP_RESULT) {
                this.resultUI.hideCurrentResult();
                gsap.delayedCall(1.5,()=> {
                    this.canvasRenderer.recordingUI.show();
                    Globals.STATE_MANAGER.transitioning = false;
                });
                this.infoDialogs.hide();
                this.viewYourSongMatchButton.classList.remove('visible');
                this.viewYourSongMatchButton.classList.remove('hidden');
                titleDelay = 1.5;
            } else {
                Globals.STATE_MANAGER.transitioning = false;
                this.tryYourOwnRecordingButton.classList.add('hidden');
            }
            this.titleManager.setVisibleTitle(2, titleDelay);
        } else if (number === StateManager.STEP_SONG_MATCH) {
            Globals.STATE_MANAGER.resultUI.saveResultToLocalStorage();
            this.viewYourSongMatchButton.classList.add('hidden');
            this.titleManager.setVisibleTitle(StateManager.STEP_SONG_MATCH, 0);
            this.infoDialogs.setStep(1);
            this.infoDialogs.show();
            this.resultUI.showAlbumResult();
        }

        this.canvasRenderer.gotoStep(number);
        StateManager.ACTIVE_STEP = number;
        return false;
    }

    showRecordingResult = (lastPredictionResult: IAPIResponse) => {
        this.canvasRenderer.recordingUI.hide();
        this.resultUI.showCurrentResult();
        this.canvasRenderer.gotoStep(StateManager.STEP_RESULT, this.resultUI.visiblePredictions);
        console.log('--');
        console.log(lastPredictionResult)
        gsap.delayedCall(4 * 0.5, () => {
            // this.infoDialogs.setStep(0);
            // this.infoDialogs.show();
            this.resultUI.songUI.showButton(1);
            this.viewYourSongMatchButton.classList.add('visible');
        });
        StateManager.ACTIVE_STEP = StateManager.STEP_RESULT;
        this.titleManager.setVisibleTitle(StateManager.STEP_RESULT, 2.5 * 0.5);
    }
}