export class Menu {
    private container = document.querySelector('.Menu');
    private hamburgerButton = document.querySelector('.Menu-hamburger button');
    private links = Array.from(this.container.querySelectorAll('nav a') as NodeListOf<HTMLAnchorElement>);
    constructor() {
        this.hamburgerButton.addEventListener('click', this.toggleMenu);
        // this.links.forEach((link) => link.addEventListener('click', this.menuItemClicked));
    }

    private toggleMenu = (event?:MouseEvent) => {
        if (event) {
            event.preventDefault();
        }
        document.documentElement.classList.toggle('menu-open');
        return false;
    }

    routeChange = (path: string) => {
        document.documentElement.classList.remove('menu-open');
        this.links.forEach((link) => {
            if (link.getAttribute('href') === '/' + path) {
                link.classList.add('active');
            } else {
                link.classList.remove('active');
            }
        })
    }
}
