export class DiscreteMediaRecorder {
  private recorder: MediaRecorder;
  private active = false;

  private constructor(recorder: MediaRecorder) {
    this.recorder = recorder;
  }

  static create(stream: MediaStream): DiscreteMediaRecorder {
    const recorder = new MediaRecorder(stream);
    return new DiscreteMediaRecorder(recorder);
  }

  setOnDataAvailable(eventHandler: (blobEvent: BlobEvent) => any) {
      this.recorder.ondataavailable = eventHandler
  }

  async start(timeslice?: number) {
      this.active = true
      while (this.active) {
          await this.recordInternalTimed(timeslice || 1000)
      }
  }

  stop() {
      this.active = false
  }

  isActive() {
      return this.active
  }

  private async recordInternalTimed(length: number): Promise<void> {
      if (this.recorder.state !== "recording") this.recorder.start();
      await new Promise(nestedResolve => setTimeout(nestedResolve, length));
      if (this.recorder.state === "recording") this.recorder.stop();
  }
}